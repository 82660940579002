import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Input, List, Switch } from 'antd';

import { State as iRootState } from '@/types';
import { Model as iModelConnection, State as iConnectionsState } from '@/state/models/connection';
import { update } from '@/state/reducers/connections';
import { getExpanded } from '@/state/selectors/connections';
import { t } from '@/utils';

interface group {
	key: string;
	title: string;
	info: any;
	description?: any;
}

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	connection: iModelConnection;
	connections: iConnectionsState;
	update: typeof update;
}

const Misc: React.FunctionComponent<Props> = (props: Props) => {
	const [alias, setAlias] = React.useState(props.connection.alias);

	const groups: group[] = [{
		title: 'Lockdown mode',
		key  : 'lockdown_mode',
		info : (record: iModelConnection) => t('connections.info_lockdown'),
	}, {
		title: 'Connection alias',
		key  : 'alias',
		info : (record: iModelConnection) => {
			return <div>
				<Input.Group compact size={'large'}>
					<Input
						value={alias}
						style={{ fontWeight: 600, width: 'calc(100% - 100px)' }}
						onChange={(e: any) => setAlias(e.target.value)}
					/>
					<Button
						size={'large'}
						disabled={alias == props.connection.alias}
						style={{ width: 100 }}
						type={'primary'}
						onClick={() => props.update(Object.assign({}, record, { alias }))}
					>Update</Button>
				</Input.Group>
				<p>{t('connections.alias_info')}</p>
			</div>;
		},
	}];

	const actionRenderer = (field: string, val: string | number | boolean, record: iModelConnection): React.ReactNode[] => {
		if (field != 'lockdown_mode') return [];

		return [<Switch defaultChecked={record[field]} onChange={(val: boolean) => {
			props.update(Object.assign({}, record, { [field]: val }));
		}}/>];
	};

	return (
		<List
			size={'small'}
			loading={props.connections.data.loading}
			itemLayout="horizontal"
			bordered={false}
			dataSource={[props.connection]}
			renderItem={(item: iModelConnection) => groups.map((group: any, i: number) => {
				return <List.Item key={group.key} actions={actionRenderer(group.key, item[group.key], item)}>
					<List.Item.Meta title={group.title} description={group.info(item)}/>
				</List.Item>;
			})}
		/>
	);
};


const mapDispatchToProps = { update };
const mapStateToProps = (state: iRootState) => ({
	connection : getExpanded(state),
	connections: state.connections,
});
export default connect(mapStateToProps, mapDispatchToProps)(Misc);

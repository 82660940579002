import * as React from 'react';
import Link from 'gatsby-link';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Form, Input, Button, Checkbox, Row, Col } from 'antd';

import { formFieldError, t } from '@/utils';
import { State as RootState } from '@/types';
import { login, reset } from '@/state/reducers/auth';
import { iState as iStateAuth } from '@/state/models/auth';

const Wrapper = styled.div``;

const ForgotPassword = styled(Link)`
	text-align: right;
	
	&:hover {
		text-decoration: none;
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	auth: iStateAuth;
	reset: () => void;
	login: typeof login;
}


const Loginform: React.FunctionComponent<Props> = (props) => {
	const { auth, reset, login, ...rest } = props;
	const [form] = Form.useForm();

	const handleSumbit = (e: any) => {
		e.preventDefault();

		const username = form.getFieldValue('username');
		const password = form.getFieldValue('password');

		login(username, password, true);
	};

	React.useEffect(() => {
		if (auth.token) {
			navigate('/app/dashboard');
		} else {
			reset();
		}
	}, []);

	const reqR = () => Object.assign({}, { required: true, message: t('misc.form.validation_required') });

	return <Wrapper {...rest}>
		<Form form={form} layout="vertical" style={{ width: '100%' }}>
			<Form.Item {...formFieldError('username', auth.validationErrors)}>
				<Form.Item name="username" noStyle rules={[reqR()]}>
					<Input placeholder={'Username'} size={'large'}/>
				</Form.Item>
			</Form.Item>

			<Form.Item {...formFieldError('password', auth.validationErrors)}>
				<Form.Item name="password" noStyle rules={[reqR()]}>
					<Input type={'password'} placeholder={'Password'} size={'large'}/>
				</Form.Item>
			</Form.Item>

			<Form.Item>
				<Row gutter={8}>
					<Col span={12}>
						<Checkbox defaultChecked={true} onChange={console.log}>Remember me</Checkbox>
					</Col>
					<Col span={12} style={{ textAlign: 'right' }}>
						<ForgotPassword to={'/app/forgot-password'}>Forgot password?</ForgotPassword>
					</Col>
				</Row>
			</Form.Item>

			<Form.Item>
				<Button
					htmlType="submit"
					type={'primary'}
					block={true}
					size={'large'}
					onClick={handleSumbit}
				>Login</Button>
			</Form.Item>

			<Form.Item>
				<Row gutter={8}>
					<Col span={12}>
						Not a member yet?
					</Col>
					<Col span={12} style={{ textAlign: 'right' }}>
						<ForgotPassword to={'/pricing'}>Register</ForgotPassword>
					</Col>
				</Row>
			</Form.Item>
		</Form>
	</Wrapper>;
};

const mapStateToProps = (state: RootState) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = { login, reset };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Loginform);

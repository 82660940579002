import * as React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';

import { State as iRootState } from '@/types';
import { filter, FILTER_COLUMN_CATEGORY } from '@/state/reducers/logs';
import { iState as iRuleCategoriesState } from '@/state/models/rule_categories';
import { iState as iStateLogs } from '@/state/models/logs';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	logs: iStateLogs;
	rule_categories: iRuleCategoriesState;
	filter: typeof filter;
}

const LogsFilterCategory: React.FunctionComponent<Props> = (props: Props) => {
	const handleChange = (v: string) => props.filter({ column: FILTER_COLUMN_CATEGORY, query: v });
	const currentlySelected = () => {
		const current = props.logs.data.filters.filter(f => f.column == FILTER_COLUMN_CATEGORY);
		return current.length ? current[0].query : undefined;
	};

	return (
		<Select
			onChange={handleChange}
			style={{ width: '100%' }}
			placeholder={'Block category'}
			size={'large'}
			showArrow={true}
			allowClear={true}
			loading={props.rule_categories.data.loading}
			notFoundContent={null}
			value={currentlySelected()}
		>
			{props.rule_categories.data.results.filter(c => c.identifier != 'allowed').map((cat) => (
				<Select.Option key={`filter-category-${cat.id}`} value={cat.id}>{cat.name}</Select.Option>
			))}
		</Select>
	);
};


const mapDispatchToProps = { filter };
const mapStateToProps = (state: iRootState) => ({
	logs           : state.logs,
	rule_categories: state.rule_categories,
});
export default connect(mapStateToProps, mapDispatchToProps)(LogsFilterCategory);

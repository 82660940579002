import * as React from 'react';
import { connect } from 'react-redux';
import { Select, Spin } from 'antd';
import ReactCountryFlag from 'react-country-flag';

import { State as iRootState } from '@/types';
import { iModel as iServerModel, iState as iServersState } from '@/state/models/servers';
import { ping as pingServer, ping } from '@/utils';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	servers: iServersState;
	onSelect: (value: any) => void;
	value?: number;
}

export interface iLatencyEntry {
	[id: number]: number;
}

const ServerSelector: React.FunctionComponent<Props> = (props: Props) => {
	const [latencies, setLatencies] = React.useState<iLatencyEntry>({});
	const [open, setOpen] = React.useState(false);

	const measureLatencies = () => {
		for (let i = 0; i < props.servers.servers.length; i++) {
			const s = props.servers.servers[i];

			// fill the accumulator with empty jobs
			setLatencies(Object.assign({}, latencies, { [s.id]: -1 }));

			setTimeout(() => {
				ping('https://' + s.fqdn + '?name=dnsadblock.com').then((() => {
					pingServer('https://' + s.fqdn + '?name=dnsadblock.com')
						.then(((res: number) => {
							setLatencies(prevData => ({
								...prevData,
								[s.id]: res,
							}));
						})).catch(console.error);
				})).catch(console.error);
			});
		}
	};

	const getLatency = (id: number): React.ReactNode => {
		if (latencies[id] == undefined && !open) return null;

		try {
			return latencies[id] === -1 || latencies[id] == undefined ? <Spin size={'small'} spinning={true}/> :
				<span><b>{latencies[id]}</b>ms</span>;
		} catch (e) {
			return null;
		}
	};

	return (
		<Select
			value={props.value}
			loading={props.servers.loading}
			onSelect={props.onSelect}
			onDropdownVisibleChange={(isIt: boolean) => {
				setOpen(isIt);
				if (isIt && Object.keys(latencies).length == 0) {
					measureLatencies();
				}
			}}
		>
			{props.servers.servers.map((server: iServerModel, k: number) => {
				return <Select.Option key={String(k)} value={server.id}>
					<ReactCountryFlag
						countryCode={server.loc_country_code}
						svg/>&nbsp;
					<b>{server.loc_country_code}</b>
					{server.loc_state && <span> / <b>{server.loc_state}</b></span>}
					{server.loc_city && <span> / <b>{server.loc_city}</b></span>}

					<span style={{ float: 'right' }}>
						{getLatency(server.id)}
					</span>
				</Select.Option>;
			})}
		</Select>
	);
};


const mapDispatchToProps = {};
const mapStateToProps = (state: iRootState) => ({
	servers: state.servers,
});
export default connect(mapStateToProps, mapDispatchToProps)(ServerSelector);

import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row, Select, Form, Slider, Spin } from 'antd';
import { IBrowser } from 'redux-responsive';
import styled from 'styled-components';

import { formFieldError, t } from '@/utils';
import { State as iRootState } from '@/types';
import { getExpanded } from '@/state/selectors/connections';
import { Model as iModelConnection, RouteTargetModel, State as iConnectionsState } from '@/state/models/connection';
import { load as loadConnections, setRouteTarget, update as updateConnection } from '@/state/reducers/connections';


const Wrapper = styled.div`
	.timeline-wrapper {
		padding-top: 10px;
		margin-top: 20px;
		position: relative;

		.ant-form-item {
			margin: 0
		}

		.ant-form-item-children-icon {
			display: none;
		}

		.ant-slider-mark {
			font-size: .8rem;
			font-family: monospace, monospace;
		}

		.grid-container {
			display: grid;
			grid-template-columns: 100px 1fr;
			grid-template-rows: 1fr;
			gap: 0 0;
			grid-template-areas: "days scheduler";

			margin-top: 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid var(--color-border);

			&:last-of-type {
				border-bottom: none;
			}

			.days {
				grid-area: days;
				font-family: monospace, monospace;
				font-weight: 600;
				font-size: .9rem;

				span {
					font-weight: 200;
					color: var(--text-color-secondary);
				}
			}

			.scheduler {
				grid-area: scheduler;
			}
		}

		.guide {
			color: var(--text-color-secondary);
		}
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	browser: IBrowser;
	connection: iModelConnection;
	connections: iConnectionsState;
	setRouteTarget: typeof setRouteTarget;
	loadConnections: typeof loadConnections;
	updateConnection: typeof updateConnection;
}

const RoutingSchedule: React.FunctionComponent<Props> = (props: Props) => {
	const [mapped, setMapped] = React.useState(props.connection.route_target?.target_id);
	const [dirty, setDirty] = React.useState(false);
	const { connection, connections, loadConnections } = props;

	const [mo, setMo] = React.useState<[number, number]>(connection.route_target?.mo ? connection.route_target?.mo : [0, 0]);
	const [tu, setTu] = React.useState<[number, number]>(connection.route_target?.tu ? connection.route_target?.tu : [0, 0]);
	const [we, setWe] = React.useState<[number, number]>(connection.route_target?.we ? connection.route_target?.we : [0, 0]);
	const [th, setTh] = React.useState<[number, number]>(connection.route_target?.th ? connection.route_target?.th : [0, 0]);
	const [fr, setFr] = React.useState<[number, number]>(connection.route_target?.fr ? connection.route_target?.fr : [0, 0]);
	const [sa, setSa] = React.useState<[number, number]>(connection.route_target?.sa ? connection.route_target?.sa : [0, 0]);
	const [su, setSu] = React.useState<[number, number]>(connection.route_target?.su ? connection.route_target?.su : [0, 0]);

	const setTarget = (targetId: number) => {
		setDirty(true);
		setMapped(targetId);
	};

	const handleSumbit = (e: any) => {
		e.preventDefault();
		if (mapped != -1) {
			props.setRouteTarget({
				target_id: mapped || -1,
				timezone : Intl.DateTimeFormat().resolvedOptions().timeZone,
				mo,
				tu,
				we,
				th,
				fr,
				sa,
				su,
			});
		}
	};

	React.useEffect(() => {
		if (!connections.data.loaded && !connections.data.loading) {
			loadConnections();
		}
	}, []);

	React.useEffect(() => {
		setMo(connection.route_target?.mo ? connection.route_target?.mo : [0, 0]);
		setTu(connection.route_target?.tu ? connection.route_target?.tu : [0, 0]);
		setWe(connection.route_target?.we ? connection.route_target?.we : [0, 0]);
		setTh(connection.route_target?.th ? connection.route_target?.th : [0, 0]);
		setFr(connection.route_target?.fr ? connection.route_target?.fr : [0, 0]);
		setSa(connection.route_target?.sa ? connection.route_target?.sa : [0, 0]);
		setSu(connection.route_target?.su ? connection.route_target?.su : [0, 0]);
	}, [props.connection]);

	const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
	const onChangeCallback = (day: string, value: React.SetStateAction<[number, number]>) => {
		switch (day) {
			case 'Mo':
				setMo(value);
				break;
			case 'Tu':
				setTu(value);
				break;
			case 'We':
				setWe(value);
				break;
			case 'Th':
				setTh(value);
				break;
			case 'Fr':
				setFr(value);
				break;
			case 'Sa':
				setSa(value);
				break;
			case 'Su':
				setSu(value);
				break;
		}
	};

	const getDayValues = (day: string): [number, number] | undefined => {
		switch (day) {
			case 'Mo':
				return mo;
			case 'Tu':
				return tu;
			case 'We':
				return we;
			case 'Th':
				return th;
			case 'Fr':
				return fr;
			case 'Sa':
				return sa;
			case 'Su':
				return su;
		}
	};

	const startDay = new Date().setHours(8, 0, 0, 0);
	const stopDay = new Date().setHours(16, 0, 0, 0);

	const formatSliderValue = (values: RouteTargetModel | undefined, day: string): [number, number] => {
		if (values == undefined) return [startDay, stopDay];
		if (values[day].length <= 0 || values[day].length > 2) {
			return [startDay, stopDay];
		}
		return values[day];
	};

	const { routeValidationErrors } = connections;

	const sliderMarks: Record<number, string> = {};
	for (let i = 0; i <= 24; i++) {
		sliderMarks[i] = i < 10 ? `0${i}` : `${i}`;
	}

	return (
		<Spin spinning={connections.data.loading} tip={'Updating connection...'}>
			<Wrapper>
				<p>
					{t('schedule.about')}

				</p>
				<p>
					{t('schedule.about_notice')}
				</p>

				<div className={'timeline-wrapper'}>
					<p className={'guide'}>
						{t('schedule.interval_help')}
					</p>
					{days.map((day) => {
						let hDiff = 0;
						const dayV = getDayValues(day);
						if (dayV) {
							hDiff = dayV[1] - dayV[0];
						}

						return (
							<div className={'grid-container'} key={day}>
								<div className={'days'}>
									{day} <br/>
									<span>{hDiff} hours</span>
								</div>
								<div className={'scheduler'}>
									<Form.Item {...formFieldError(day.toLowerCase(),
										routeValidationErrors)}>
										<Slider
											range
											dots
											min={0}
											max={24}
											tooltipVisible={false}
											marks={sliderMarks}
											defaultValue={formatSliderValue(
												connection.route_target,
												day.toLowerCase(),
											)}
											value={getDayValues(day)}
											onChange={(value: [number, number]) => onChangeCallback(
												day, value as [number, number],
											)}
										/>
									</Form.Item>
								</div>

								<div style={{ clear: 'both' }}/>
							</div>
						);
					})}
				</div>


				<Row gutter={40} style={{ marginTop: 20 }}>
					<Col
						xs={24}
						md={24}
						lg={12}
						style={{
							textAlign: props.browser.lessThan.large ? 'left' : 'right',
						}}
					>
						<b>Timezone</b>
					</Col>
					<Col xs={24} md={24} lg={12}>
						<div>{Intl.DateTimeFormat().resolvedOptions().timeZone}</div>
					</Col>
				</Row>

				<Row gutter={40} style={{ marginTop: 20 }}>
					<Col
						xs={24}
						md={24}
						lg={12}
						style={{
							textAlign: props.browser.lessThan.large ? 'left' : 'right',
						}}
					>
						<b>{t('schedule.configuration')}</b>
					</Col>
					<Col xs={24} md={24} lg={12}>
						{connections.data.results.length > 0 && (
							<Form.Item
								{...formFieldError('target_id', routeValidationErrors)}
							>
								<Select
									value={mapped}
									placeholder={t('devices.select_configuration')}
									size={'large'}
									style={{ width: '100%' }}
									onSelect={(value, option) => setTarget(Number(value))}
								>
									<Select.Option key={`conn-reroute-none`} value={0}>
										None
									</Select.Option>
									{connections.data.results.filter((v) => v.id != connection.id).map(
										(conn) => (
											<Select.Option key={`conn-dev-${conn.id}`}
													   value={conn.id}>
												{conn.alias}
											</Select.Option>
										))}
								</Select>
							</Form.Item>
						)}

						{dirty && (
							<Button
								type="primary"
								style={{ marginTop: 10 }}
								onClick={handleSumbit}
							>Save</Button>
						)}

						{connections.data.results.length == 0 && <p>{t('devices.no_connections')}</p>}
					</Col>
				</Row>
			</Wrapper>
		</Spin>
	);
};


const mapDispatchToProps = { loadConnections, setRouteTarget, updateConnection };
const mapStateToProps = (state: iRootState) => ({
	browser    : state.browser,
	connection : getExpanded(state),
	connections: state.connections,
});
export default connect(mapStateToProps, mapDispatchToProps)(RoutingSchedule);

import * as React from 'react';

import { PaginationFilter, PaginationPayload, PaginationSorter } from '@/types';
import { iData as RulesData } from '../state/models/rules';

export interface Props {
	load: () => void;
	paginate: (params: PaginationPayload) => void;
	data: RulesData;
}

export interface State {

}

export default class PaginatedFilteredSortedTable<P extends Props, S extends State> extends React.Component<P, S> {
	constructor(props: Readonly<P>) {
		super(props);

		if (!this.props.hasOwnProperty('load')) {
			throw Error('The `load` function is not available in props');
		}

		if (!this.props.hasOwnProperty('paginate')) {
			throw Error('The `paginate` function is not available in props');
		}
	}

	componentDidMount(): void {
		this.props.load();
	}

	handleTableChange(pagination: any, filters: any, sorter: any) {
		const pageSize = pagination.pageSize;
		const pageNumber = pagination.current;
		const pFilters: PaginationFilter[] = [];
		const pSorters: PaginationSorter[] = [];

		if (sorter && sorter.order) {
			pSorters.push({
				column: sorter.field,
				order : sorter.order,
			});
		}

		this.props.paginate({
			pageNumber, pageSize, filters: pFilters, sorters: pSorters,
		});
	}

	handleSearch(columnName: string, value: any) {
		let filter: PaginationFilter[] = [];

		if (value.length) {
			filter = [{
				column: columnName,
				query : value[0],
			}];
		}

		this.props.paginate({
			pageNumber: this.props.data.pageNumber,
			filters   : filter,
			sorters   : this.props.data.sorters,
		});
	}

	handleReset(clearFilters: any) {
		clearFilters();

		this.props.paginate({
			pageNumber: 1, filters: [], sorters: [],
		});
	}
}

import * as React from 'react';

import { Col, Row } from 'antd';

import StatsConnections from '../stats/connections';
import StatsRulesCategories from '../stats/rules_categories';

const QuickStatsPanel: React.FunctionComponent = () => {
	return <Row gutter={[16, 20]}>
		<Col xs={24} md={24} lg={12}>
			<StatsConnections
				style={{ height: '100%' }}
				title={<div>Blocked <b>by configuration</b></div>}
			/>
		</Col>
		<Col xs={24} md={24} lg={12}>
			<StatsRulesCategories
				style={{ height: '100%' }}
				title={<div>Blocked <b>by category</b></div>}
			/>
		</Col>
	</Row>;
};

export default QuickStatsPanel;

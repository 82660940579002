import React from 'react';
import UnixClientGuide from './unix-client-guide';

interface Props {
	version: string;
}

export default ({ version }: Props) => {
	return (
		<div style={{ marginTop: 40 }}>
			<div style={{ marginBottom: 60 }}>
				<UnixClientGuide/>
			</div>
		</div>
	);
};

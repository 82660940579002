import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Descriptions } from 'antd';
import { State as iRootState } from '@/types';
import { iState } from '@/state/models/device';
import { unixToDateTime } from '@/utils';

const Wrapper = styled(Descriptions)`
	.ant-descriptions-view{
		border-radius: 0;
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	devices: iState;
}

const Device: React.FunctionComponent<Props> = (props: Props) => {
	const { devices, ...rest } = props;
	const device = props.devices.selected;
	const last_seen = device ? unixToDateTime(device?.last_seen) : 'never';
	return (
		<Wrapper column={{ sm: 2, xs: 1 }} bordered size={'small'} {...rest}>
			<Descriptions.Item label="Id">{device?.device_id}</Descriptions.Item>
			<Descriptions.Item label="Name">{device?.device_name}</Descriptions.Item>
			<Descriptions.Item label="Local Ip">{device?.device_ip}</Descriptions.Item>
			<Descriptions.Item label="Model">{device?.device_model}</Descriptions.Item>
			<Descriptions.Item label="Blocked requests">{device?.block_count}</Descriptions.Item>
			<Descriptions.Item label="Allowed requests">{device?.allow_count}</Descriptions.Item>
			<Descriptions.Item label="Last seen">{last_seen}</Descriptions.Item>
		</Wrapper>
	);
};


const mapDispatchToProps = {};
const mapStateToProps = (state: iRootState) => ({
	devices: state.devices,
});
export default connect(mapStateToProps, mapDispatchToProps)(Device);

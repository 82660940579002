import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Statistic, Card, Progress, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons/lib';

import { State as iRootState } from '@/types';
import { iState, iModel } from '@/state/models/stats/protocols';
import { t, percentValFromTotal } from '@/utils';

const Wrapper = styled(Card)`
	height: 100%;
	
	.legend {
		color: #666;
		b {
			color: #333;
		}
	}
	
	.ant-progress-success-bg{
		background-color: var(--color-primary)!important;
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	stats: iState;
}

const StatsProtocols: React.FunctionComponent<Props> = (props: Props) => {
	const totalRequests = props.stats.data.reduce((accumulator: number, record: iModel) => {
		return accumulator + record.total;
	}, 0);

	const totalEncrypted = props.stats.data.reduce((accumulator: number, record: iModel) => {
		if (record.protocol != 3 && record.protocol != 4) return accumulator;
		return accumulator + record.total;
	}, 0);

	const encryptedPercent = percentValFromTotal(totalEncrypted, totalRequests) || 0;

	return (
		<Wrapper loading={props.stats.loading}>
			<Statistic
				title={<div>
					<Tooltip title={t('stats.encrypted_queries_desc')} placement={'bottom'}>
						<InfoCircleFilled/>
					</Tooltip> {t('stats.encrypted_queries')}
				</div>}
				value={encryptedPercent}
				precision={2}
				valueStyle={{ fontWeight: 800, fontSize: '3rem' }}
				suffix="%"
			/>

			<Progress
				strokeColor={'var(--color-accent-secondary)'}
				percent={100}
				success={{
					percent: encryptedPercent
				}}
				showInfo={false}
			/>
			<span className={'legend'}>
				<b>{Number(totalEncrypted).toLocaleString()}</b> of&nbsp;
				queries on <b>HTTPS</b> or <b>TLS</b>
			</span>
		</Wrapper>
	);
};


const mapDispatchToProps = {};
const mapStateToProps = (state: iRootState) => ({
	stats: state.stats_protocols,
});
export default connect(mapStateToProps, mapDispatchToProps)(StatsProtocols);

import * as React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import debounce from 'lodash/debounce';

import { State as iRootState } from '@/types';
import { iState as iStateLogs } from '@/state/models/logs';
import { iState as iStateDevices } from '@/state/models/device';
import { filter, FILTER_COLUMN_DEVICE } from '@/state/reducers/logs';
import { load, paginate } from '@/state/reducers/devices';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	logs: iStateLogs;
	devices: iStateDevices;

	load: typeof load;
	paginate: typeof paginate;
	filter: typeof filter;
}

const LogsFilterDevice: React.FunctionComponent<Props> = (props: Props) => {
	const { Option } = Select;

	const handleChange = (v: string) => {
		props.filter({ column: 'device', query: v });
	};

	const handleSearch = (v: string) => {
		props.paginate({
			filters   : v ? [{
				column: FILTER_COLUMN_DEVICE,
				query : v,
			}] : [],
			pageNumber: 1,
			pageSize  : 100,
		});
	};

	const currentValue = (): string | undefined => {
		const curr = props.logs.data.filters.filter(f => f.column == FILTER_COLUMN_DEVICE);
		if (curr.length > 0) {
			return curr[0].query;
		}
		return undefined;
	};

	const handleFocus = () => {
		if (!props.devices.data.loaded && !props.devices.data.loading) props.load();
	};

	return (
		<Select
			showSearch
			style={{ width: '100%' }}
			placeholder={'Device'}
			size={'large'}
			value={currentValue()}
			showArrow={true}
			allowClear={true}
			filterOption={false}
			labelInValue={false}
			loading={props.devices.data.loading}
			onFocus={handleFocus}
			onSearch={debounce(handleSearch, 800)}
			onChange={handleChange}
			notFoundContent={null}
		>
			{props.devices.data.results.map((d) => (
				<Option key={`filter-device-${d.id}`} value={d.id}>{d.device_name}</Option>
			))}
		</Select>
	);
};


const mapDispatchToProps = { load, paginate, filter };
const mapStateToProps = (state: iRootState) => ({
	logs   : state.logs,
	devices: state.devices,
});
export default connect(mapStateToProps, mapDispatchToProps)(LogsFilterDevice);

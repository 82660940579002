import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Select, Button } from 'antd';

import { t } from '@/utils';
import { State as iRootState } from '@/types';
import styled from 'styled-components';
import { State as iConnectionsState } from '@/state/models/connection';
import { map2Connection, toggleStatsDrawer } from '@/state/reducers/devices';
import { load as loadConnections } from '@/state/reducers/connections';
import { iState } from '@/state/models/device';

const Wrapper = styled.div`
	p {
		color: var(--text-color-secondary);
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	devices: iState;
	connections: iConnectionsState;
	map2Connection: typeof map2Connection;
	loadConnections: typeof loadConnections;
	toggleStatsDrawer: typeof toggleStatsDrawer;
}

const DeviceConnectionMapForm: React.FunctionComponent<Props> = (props: Props) => {
	const [mapped, setMapped] = React.useState(-1);
	const { devices, connections, map2Connection, loadConnections, toggleStatsDrawer } = props;

	React.useEffect(() => {
		if (!connections.data.loaded && !connections.data.loading) {
			loadConnections();
		}
	}, []);

	const device = devices.selected;

	const handleSumbit = (e: any) => {
		e.preventDefault();
		if (device != undefined) {
			map2Connection(mapped, device);
			setMapped(-1);
			toggleStatsDrawer(false);
		}
	};

	return (
		<Wrapper style={{ padding: '20px 15px' }}>
			<Row gutter={20}>
				<Col xs={24} md={24} lg={12}>
					<b>{t('devices.connection_map_title')}</b>
					<p>{t('devices.connection_map_info')}</p>
				</Col>
				<Col xs={24} md={24} lg={12}>
					<b>&nbsp;</b>
					{connections.data.results.length > 0 && (
						<Select
							value={device && device.connection ? device.connection : undefined}
							placeholder={t('devices.select_configuration')}
							size={'large'}
							style={{ width: '100%' }}
							onSelect={setMapped}
						>
							{device && device.connection && (
								<Select.Option key={`conn-dev-0`} value={0}>
									No mapping
								</Select.Option>
							)}

							{connections.data.results.map((conn) => (
								<Select.Option key={`conn-dev-${conn.id}`} value={conn.id}>
									{conn.alias}
								</Select.Option>
							))}
						</Select>
					)}

					{mapped >= 0 && (
						<Button
							type="primary"
							style={{ marginTop: 10 }}
							onClick={handleSumbit}
						>Save</Button>
					)}

					{connections.data.results.length == 0 && <p>{t('devices.no_connections')}</p>}
				</Col>
			</Row>
		</Wrapper>
	);
};


const mapDispatchToProps = { loadConnections, map2Connection, toggleStatsDrawer };
const mapStateToProps = (state: iRootState) => ({
	devices    : state.devices,
	connections: state.connections,
});
export default connect(mapStateToProps, mapDispatchToProps)(DeviceConnectionMapForm);

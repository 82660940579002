import { createSelector } from 'reselect';

import { State as iRootState } from '@/types';
import { TYPE_ALLOW, TYPE_BLOCK } from '@/state/models/rule_categories';

const getCategories = (state: iRootState) => state.rule_categories;

// Returns the first (and only?) category of type allowed
export const getAllowed = createSelector(
	[getCategories],
	(categories) => {
		const filtered = categories.data.results.filter(c => c.type == TYPE_ALLOW);
		return filtered.length ? filtered[0] : null;
	},
);

// Returns the ads category or another one (random). Used when the user
// wants to block a logged name - because we're not asking/trusting the
// user to provide a category for the block
export const getBlocked = createSelector(
	[getCategories],
	(categories) => {
		const filtered = categories.data.results.filter(c => c.type == TYPE_BLOCK);
		const adsC = filtered.filter(b => b.identifier == 'ads');
		return adsC.length ? adsC[0] : (filtered.length ? filtered[0] : null);
	},
);

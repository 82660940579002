import React from 'react';
import { navigate } from 'gatsby';

import { iState as StateAuth } from '../state/models/auth';

const PrivateRoute = ({ component: Component, auth, path: string, ...rest }: {
	component: any;
	auth: StateAuth;
	path: string;
}) => {
	if ((!auth || !auth.token) && window.location.pathname !== `/app/login`) {
		// If the user is not logged in, redirect to the login page.
		navigate(`/app/login`);
		return null;
	}

	return <Component {...rest} />;
};

export default PrivateRoute;

import * as React from 'react';

import Layout from '../layout';
import SEO from '../seo';
import Billing from '../billing';


interface Props {

}

interface State {

}

export default class ConnectionsPage extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return <Layout title={'Billing'}>
			<SEO title="Billing"/>
			<div style={{ marginTop: 20 }}>
				<Billing/>
			</div>
		</Layout>;
	}
}

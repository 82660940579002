import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Row, notification } from 'antd';

import Layout from '../layout';
import SEO from '../seo';
import ProfileForm from '../profile';
import Plans from '../profile/plans';
import { State as RootState } from '@/types';
import { deleteProfile } from '@/state/reducers/profile';


interface Props {
	deleteProfile: typeof deleteProfile;
}

interface State {

}

export class ProfilePage extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	deleteAccount = () => {
		const key = `open${Date.now()}`;
		const btn = (
			<Button type="primary" size="large" block danger onClick={() => {
				this.props.deleteProfile();
				notification.close(key);
			}}>
				Confirm
			</Button>
		);

		notification.open({
			duration   : 0,
			placement  : 'bottomRight',
			message    : 'Unreversible action',
			description:
				'This will delete your account and everything in our database that is related to your' +
				' account. Do you wish to proceed?',
			btn,
			key,
		});
	};

	render() {
		return <Layout titleProps={{
			title: 'Profile settings',
		}}>
			<SEO title="Profile"/>

			<Row gutter={48} style={{ marginTop: 20 }}>
				<Col className="gutter-row" md={24} lg={12} style={{ marginBottom: 40 }}>
					<Card title={<b>Update your info</b>}>
						<ProfileForm/>
					</Card>
				</Col>

				<Col className="gutter-row" md={24} lg={12}>
					<Plans/>

					<Button
						style={{ marginTop: 20 }}
						type="primary"
						danger
						size={'large'}
						block
						onClick={this.deleteAccount}
					>
						Delete account
					</Button>
				</Col>
			</Row>
		</Layout>;
	}
}

const mapStateToProps = (state: RootState) => {
	return {
		profile: state.profile,
	};
};

const mapDispatchToProps = { deleteProfile };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProfilePage);


import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Tabs, Divider } from 'antd';

import { State as iRootState } from '@/types';
import { navigate } from 'gatsby';
import { getExpanded } from '@/state/selectors/connections';
import { Model as iModelConnection } from '@/state/models/connection';

const Wrapper = styled(Tabs)`
	h4 {font-weight: 600; font-size: 1.1rem}
	code {color: deeppink; font-weight: 600}
	pre {background: var(--color-border); padding: 10px; margin: 10px 0}
`;

const { TabPane } = Tabs;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	connection: iModelConnection;
}

const ownSoftware = (): React.ReactNode => {
	return (
		<div>
			<p>
				The recommended way is to install our software because
				the protocol is upgraded and forced to use DOH (DNS over
				https) which makes the communication not only safer due
				to https but also to appear as regular http traffic.
			</p>

			<p>
				To download our software please head to our <b><a
				href={'#'} onClick={() => navigate('/app/download')}>
				Downloads page</a></b> and continue from there.
			</p>
		</div>
	);
};

const dynamicIpAddress = (): React.ReactNode => {
	return (
		<div>
			<p>
				<b><u>Remember</u></b> that our servers have no way of knowing who you
				are besides your ip address which must be added to the list of allowed
				ip addresses otherwise your requests will be denied.
			</p>

			<p>
				If you're using our software, the authentication is done in other ways,
				since the protocol is upgraded and we can transmit more information to
				the servers. In that case you no longer have to keep the allowed ip
				address updated in the dashboard.
			</p>
		</div>
	);
};

const SetupInstructions: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Wrapper>
			<TabPane tab="Windows" key="1">
				<h4>How to change your DNS server on a Windows machine</h4>

				<ol>
					<li>Go to the <strong>Control Panel</strong>.</li>
					<li>Click <strong>Network and Internet</strong> &gt; <strong>Network and Sharing
						Center</strong> &gt; <strong>Change
						adapter settings</strong>.
					</li>
					<li>
						<p>
							Select the connection for which you want to configure DNS, right-click the
							Ethernet interface and select <strong>Properties</strong>
						</p>

						<p>
							If you are prompted for an administrator password or confirmation, type the
							password or provide confirmation.</p>
					</li>
					<li>
						<p>
							Select the <strong>Networking</strong> tab. Under <strong>This connection uses
							the following items</strong>, select <strong>Internet Protocol Version 4
							(TCP/IPv4)</strong> or <strong>Internet Protocol Version 6
							(TCP/IPv6)</strong> and then click <strong>Properties</strong>.
						</p>
					</li>
					<li>
						<p>
							Click <strong>Advanced</strong> and select the <strong>DNS</strong> tab.
						</p>
					</li>
					<li>
						<p>Click <strong>OK</strong>.</p>
					</li>
					<li>
						<p>Select <strong>Use the following DNS server addresses</strong>.</p>
					</li>
					<li><p>Replace those addresses with the IP addresses of the Google DNS servers:</p>

						<ul>
							<li>For IPv4: <b>{props.connection.server?.ipv4}</b></li>
							<li>For IPv6: <b>{props.connection.server?.ipv6}</b></li>
						</ul>
					</li>
					<li>
						<p>Repeat the procedure for additional network connections you want to change.</p>
					</li>
				</ol>

				<Divider/>

				<h4>Note on dnyamic ip addresses</h4>
				{dynamicIpAddress()}

				<Divider/>


				<h4>Our recommended way</h4>
				<div>{ownSoftware()}</div>
			</TabPane>

			<TabPane tab="MacOS" key="2">
				<h4>How to change your DNS server on a MAcOS machine</h4>

				<ol>
					<li>
						Click <strong>Apple menu</strong> &gt; <strong>System Preferences</strong> &gt;
						<strong>Network</strong>.
					</li>
					<li>
						If the lock icon in the lower left-hand corner of the window is locked,
						click the icon to make changes, and when prompted to authenticate, enter
						your password.
					</li>
					<li>
						Select the connection for which you want to configure DNS and
						click <strong>Advanced</strong>.
					</li>
					<li>Select the <strong>DNS</strong> tab.</li>
					<li>
						Click <strong>-</strong> to remove any DNS servers present and then
						click <strong>+</strong> and add the IP address from DnsAdBlock:
						<ul>
							<li>For IPv4: <b>{props.connection.server?.ipv4}</b></li>
							<li>For IPv6: <b>{props.connection.server?.ipv6}</b></li>
						</ul></li>
					<li>Click <strong>OK</strong> &gt; <strong>Apply</strong>.</li>
					<li>Repeat the procedure for additional network connections you want to change.</li>
				</ol>

				<Divider/>

				<h4>Note on dnyamic ip addresses</h4>
				{dynamicIpAddress()}

				<Divider/>


				<h4>Our recommended way</h4>
				<div>{ownSoftware()}</div>
			</TabPane>

			<TabPane tab="Linux" key="3">
				Consult with your Linux distro guides on how to change the nameservers. Usually it is done by
				editing the <code>/etc/resolv.conf</code> file and adding our IP address to the nameservers
				list - make sure it is the only one:

				<pre>
					nameserver {props.connection.server?.ipv4}
				</pre>
			</TabPane>

			<TabPane tab="Android" key="4">
				<p>
					Android supports changing the DNS servers but the setting can be altered only for
					WiFi connections. Please install our software for total coverage.
				</p>

				<h4>Our recommended way</h4>
				<div>{ownSoftware()}</div>
			</TabPane>

			<TabPane tab="iOS" key="5">
				<p>
					iOS added support for custom DNS servers in the recent upgrade to iOS 14.0 and we're
					working hard to provide a custom app for you. Please come back at a later time.
				</p>
			</TabPane>

			<TabPane tab="Routers" key="6">
				<p>
					Consult with your router's manual on changing the DNS servers and set them to use your
					allocated DNS IP address:
				</p>
				<ul>
					<li>
						<b>{props.connection.server?.ipv4}</b> (for IPv4)
					</li>
					<li>
						<b>{props.connection.server?.ipv6}</b> (for IPv6)
					</li>
				</ul>

				<Divider/>

				<h4>Notes on dynamic IP addresses</h4>
				<p>
					<b><u>Remember</u></b> that our servers have no way of knowing who you
					are besides your ip address which must be added to the list of allowed
					ip addresses otherwise your requests will be denied.
				</p>
				<p>
					Most routers support updating a dynamic DNS service such as DynDNS or NoIP and that
					change can automate the inconvenience of using it manually every time your IP address
					changes. Please see <a
					href={'https://knowledgebase.dnsadblock.com/how-to-update-your-connection-details-if-you-have-a-dynamic-ip-address/'}
					target={'_blank'}>our guides</a> on setting them up.
				</p>
			</TabPane>
		</Wrapper>
	);
};


const mapDispatchToProps = {};
const mapStateToProps = (state: iRootState) => ({
	connection: getExpanded(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(SetupInstructions);

import * as React from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Spin, InputNumber, Select, Col } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';

import { State as RootState } from '../../types';
import { iState as iRuleCategoriesState, TYPE_BLOCK } from '../../state/models/rule_categories';
import { State as iConnectionsState } from '../../state/models/connection';
import { iState as iRuleState } from '../../state/models/rule';
import { iModel as iRuleModel } from '../../state/models/rules';
import { formChange, formReset, update } from '../../state/reducers/rule';
import { prepareFormValues } from '../../utils';


export interface Props extends FormComponentProps {
	rule: iRuleState;
	connections: iConnectionsState;
	rule_categories: iRuleCategoriesState;

	formChange: (allValues: any) => void;
	formReset: () => void;
	update: (data: iRuleModel) => void;
}


const RuleForm = Form.create({
	name: 'rule_form',
	mapPropsToFields(props: any) {
		return props.rule.rule ? {
			rule       : Form.createFormField({
				...props.rule.form.data.rule,
				value: props.rule.form.data.rule || props.rule.rule.rule,
			}),
			category   : Form.createFormField({
				...props.rule.form.data.category,
				value: props.rule.form.data.category || props.rule.rule.category,
			}),
			connections: Form.createFormField({
				...props.rule.form.data.connections,
				value: props.rule.form.data.connections || props.rule.rule.connections,
			}),
			age_min    : Form.createFormField({
				...props.rule.form.data.age_min,
				value: props.rule.form.data.age_min || props.rule.rule.age_min,
			}),
		} : {};
	},
	onValuesChange(props: any, values) {
		props.formChange(values);
	},
})((props: Props) => {
	const { form, update, rule, connections, rule_categories } = props;
	const { getFieldDecorator } = form;

	const getErr = (field: string): any => {
		return hasErr(field) ? {
			help          : rule.form.errors[field],
			validateStatus: 'error',
		} : {};
	};

	const hasErr = (field: string): boolean => {
		return rule.form.errors.hasOwnProperty(field);
	};

	const ageMinDisplay = (): string => {
		const selectedCategory = form.getFieldValue('category');
		const category = rule_categories.data.results.filter((rg) => rg.id == selectedCategory)[0];
		return category && category.type == TYPE_BLOCK ? 'block' : 'none';
	};

	const handleUpdate = () => {
		form.validateFields((err: string, values: string[]) => {
			if (err) {
				return;
			}

			rule.rule && update(prepareFormValues(values));
		});
	};

	return (
		<Spin tip={'Updating profile'} spinning={rule.form.submitting}>
			<Form layout="vertical">
				<Form.Item
					label={'Rule'}
					{...getErr('rule')}
				>
					{getFieldDecorator('rule', {
						rules: [{
							required: true,
						}],
					})(
						<Input size={'large'}/>,
					)}
				</Form.Item>

				<Form.Item
					label="Category"
					className="collection-create-form_last-form-item"
					{...getErr('category')}
				>
					{getFieldDecorator('category', {
						rules: [{ required: true, message: 'Please enter a rule category' }],
					})(
						<Select placeholder="Select a category">
							{rule_categories.data.results.map((rg) => <Select.Option
									key={`rform-rg-${rg.id}`}
									value={rg.id}
								>
									{rg.name}
								</Select.Option>,
							)}
						</Select>,
					)}
				</Form.Item>

				<Form.Item
					label="Required age"
					{...getErr('age_min')}
					extra={`If you want to create age based restrictions enter an age here.
									Leave 0 for no age restriction`}
					style={{
						//	if the selected group is of allow type we hide this input
						display: ageMinDisplay(),
					}}
				>
					{getFieldDecorator('age_min', {
						initialValue: 0,
						rules       : [{
							required: false,
							message : 'Age must be between 1 and 18',
						}],
					})(
						<InputNumber min={0} max={18}/>,
					)}
				</Form.Item>

				<Form.Item
					label="Affected connections"
					className="collection-create-form_last-form-item"
					{...getErr('connections')}
					extra={`Leave empty to affect all connections`}
				>
					{getFieldDecorator('connections', {})(
						<Select
							placeholder="Select connections"
							mode="multiple"
						>
							{connections.data.results.map((rg) => <Select.Option
									key={`rform-rg-${rg.id}`}
									value={rg.id}
								>
									{rg.alias}
								</Select.Option>,
							)}
						</Select>,
					)}
				</Form.Item>

				<Button type="primary" onClick={handleUpdate}>Update</Button>
			</Form>
		</Spin>
	);
});


const mapStateToProps = (state: RootState) => {
	return {
		rule           : state.rule,
		connections    : state.connections,
		rule_categories: state.rule_categories,
	};
};

const mapDispatchToProps = { formChange, formReset, update };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RuleForm);

import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Statistic, Card, Progress, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons/lib';

import { State as iRootState } from '@/types';
import { load } from '@/state/reducers/stats/protocols';
import { iState as iDevicesState } from '@/state/models/device';
import { t, percentValFromTotal } from '@/utils';

const Wrapper = styled(Card)`
	height: 100%;
	
	.legend {
		color: #666;
		b {
			color: #333;
		}
	}
	
	.ant-progress-success-bg{
		background-color: var(--color-primary)!important;
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	devices: iDevicesState;
}

const AllTimeBlockedStats: React.FunctionComponent<Props> = (props: Props) => {
	const blocked = props.devices.selected?.block_count || 0;
	const deviceTotal = (props.devices.selected?.allow_count || 0) + blocked;
	const allTimePercent = percentValFromTotal(props.devices.selected?.block_count || 0, deviceTotal) || 0;

	return (
		<Wrapper loading={props.devices.data.loading}>
			<Statistic
				title={<div>
					<Tooltip title={t('devices.stats.total_requests_blocked_tooltip')} placement={'bottom'}>
						<InfoCircleFilled/>
					</Tooltip> {t('devices.stats.total_requests_blocked')}
				</div>}
				value={allTimePercent}
				precision={2}
				valueStyle={{ fontWeight: 800, fontSize: '3rem' }}
				suffix="%"
			/>

			<Progress
				strokeColor={'var(--color-accent-secondary)'}
				percent={allTimePercent}
				showInfo={false}
			/>
			<span className={'legend'}>
				<b>{Number(blocked).toLocaleString()}</b> queries blocked
			</span>
		</Wrapper>
	);
};


const mapDispatchToProps = { load };
const mapStateToProps = (state: iRootState) => ({
	devices: state.devices,
});
export default connect(mapStateToProps, mapDispatchToProps)(AllTimeBlockedStats);

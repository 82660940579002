import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import gravatarUrl from 'gravatar-url';
import { Avatar, Card, Skeleton } from 'antd';

import { State as iRootState } from '@/types';
import { iState } from '@/state/models/profile';
import { iState as iPlansState } from '@/state/models/plans';

const Wrapper = styled(Card)`
	height: 100%;

	ul {
		margin: 16px 0 0 0;
		padding:0;
		list-style-type: none;
		
		li {
			margin: 0;
			padding: 4px 0 2px 0;
			b {
				float: right;
				color: #333;
			}
		}
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	plans: iPlansState;
	profile: iState;
}

const ProfileCard: React.FunctionComponent<Props> = (props: Props) => {
	const { profile, plans } = props;
	const plan = profile.data.subscription_plan ? plans.data.results.filter(p => p.id == profile.data.subscription_plan) : null;
	return (
		<Wrapper loading={profile.data.loading}>
			<Skeleton loading={profile.data.loading} avatar active>
				<Card.Meta
					avatar={
						<Avatar size={50} shape="square" src={gravatarUrl(
							profile.data.email || 'unknown@asdlkajsd.com', {
								size: 50,
							})}/>
					}
					title={<b>{profile.data.username}</b>}
					description={profile.data.email}
				/>

				{plan?.length && (
					<ul>
						<li>
							<span>Plan</span>
							<b>{plan && plan[0].name}</b>
						</li>
						<li>
							<span>Max. connections</span>
							<b>{plan && plan[0].max_connections}</b>
						</li>
						<li>
							<span>Max. monthly queries</span>
							<b>{Number(plan && plan[0].max_monthly_queries || 0).toLocaleString()}</b>
						</li>
					</ul>
				)}
			</Skeleton>
		</Wrapper>
	);
};


const mapDispatchToProps = {};
const mapStateToProps = (state: iRootState) => ({
	plans  : state.plans,
	profile: state.profile,
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);

import * as React from 'react';
import { connect } from 'react-redux';

import { Card, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import { State as iRootState } from '../../../types';
import { setInterval, iState as iStateDeviceStats } from '../../../state/reducers/device_stats';

import BarChart from '@/components/dashboard/charts/connections-charts';


interface Props {
	title: string;
	setInterval: (i: string) => void;

	device_stats: iStateDeviceStats;
}

interface State {

}

export class Timelinechart extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	handleSizeChange = (e: RadioChangeEvent) => {
		this.props.setInterval(e.target.value);
	};

	render() {
		const { title, device_stats } = this.props;

		const aBlocked = device_stats.results.map((r) => r.blocked);
		const aAllowed = device_stats.results.map((r) => r.allowed);
		const aTimestamps = device_stats.results.map((r) => new Date(`${r.timestamp}`).toString());

		return <Card title={title} bordered={false} extra={
			<Radio.Group buttonStyle="solid" value={device_stats.interval} onChange={this.handleSizeChange}>
				<Radio.Button value="1-M">30d</Radio.Button>
				<Radio.Button value="1-W">7d</Radio.Button>
				<Radio.Button value="1-D">24h</Radio.Button>
				<Radio.Button value="1-h">1h</Radio.Button>
			</Radio.Group>
		} loading={device_stats.loading}>
			<BarChart
				blocked={aBlocked}
				allowed={aAllowed}
				timestamps={aTimestamps}
			/>
		</Card>;
	}
}

const mapStateToProps = (state: iRootState) => {
	return {
		device_stats: state.device_stats,
	};
};

const mapDispatchToProps = { setInterval };

export default connect(mapStateToProps, mapDispatchToProps)(Timelinechart);


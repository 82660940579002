import * as React from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import { connect } from 'react-redux';

import { Form, Input, Button, message } from 'antd';

import { formFieldError, t } from '@/utils';
import { State as RootState } from '@/types';
import { iState as iStateAuth } from '@/state/models/auth';
import { recoverPasswordUrl } from '@/routes';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	auth: iStateAuth;
}

const ForgotPasswordForm: React.FunctionComponent<Props> = (props) => {
	const { auth, ...rest } = props;
	const [error, setError] = React.useState('');
	const [form] = Form.useForm();

	const handleSumbit = (e: any) => {
		e.preventDefault();
		const email = form.getFieldValue('email');
		if (!email || email.length == 0) {
			return setError('Please provide an email or username');
		} else {
			setError('');
		}

		axios.post(recoverPasswordUrl, { email }).then(() => {
			form.resetFields();
			message.success('If found, your email will receive a message with instructions.');
		}).catch(console.error);
	};

	React.useEffect(() => {
		if (auth.token) navigate('/app/dashboard');
	}, []);

	const reqR = () => Object.assign({}, { required: true, message: t('misc.form.validation_required') });

	return <div {...rest}>
		<Form form={form} layout="vertical" style={{ width: '100%' }}>
			<Form.Item {...formFieldError('email', { email: error ? [error] : undefined })}>
				<Form.Item name="email" noStyle rules={[reqR()]}>
					<Input placeholder={'Your email'} size={'large'}/>
				</Form.Item>
			</Form.Item>

			<Form.Item>
				<Button
					htmlType="submit"
					type={'primary'}
					block={true}
					size={'large'}
					onClick={handleSumbit}
					style={{ marginTop: 10 }}
				>Send recovery email</Button>
			</Form.Item>
		</Form>
	</div>;
};

const mapStateToProps = (state: RootState) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ForgotPasswordForm);

import * as React from 'react';
import { connect } from 'react-redux';
import { Form as AntdForm } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Spin } from 'antd';


import { update, formChange, formReset } from '../../state/reducers/profile';
import { iModel, iState as ProfileState } from '../../state/models/profile';
import { State as RootState } from '../../types';


interface FormProps {
	submitting: boolean;
	form: any;
	validationErrors?: any;
	onSubmit: any;
	onChange: any;
	profile: {
		data: iModel
	};
}

interface FormState {
}

class Form extends React.Component<FormProps, FormState> {
	render() {
		const { form, validationErrors }: FormProps = this.props;

		const { getFieldDecorator } = form;

		return (
			<Spin tip={'Updating profile'} spinning={false}>
				<AntdForm layout="vertical">
					<AntdForm.Item
						label={'Username'}
					>
						{getFieldDecorator('username', {})(
							<Input size={'large'} disabled={true}/>,
						)}
					</AntdForm.Item>
					<AntdForm.Item
						label={'API Auth Token (readonly)'}
					>
						{getFieldDecorator('token', {})(
							<Input size={'large'} disabled={false}/>,
						)}
					</AntdForm.Item>
					<AntdForm.Item
						label="Email"
						{...validationErrors.email && {
							help          : validationErrors.email,
							validateStatus: 'error',
						}}
					>
						{getFieldDecorator('email', {
							rules: [{
								required: true,
								message : 'Please enter a valid email',
							}, {
								type   : 'email',
								message: 'Please enter a valid email',
							}],
						})(
							<Input size={'large'} type={'email'}/>,
						)}
					</AntdForm.Item>

					<AntdForm.Item
						label="Current password"
						{...validationErrors.current_password && {
							help          : validationErrors.current_password,
							validateStatus: 'error',
						}}
					>
						{getFieldDecorator('current_password', {
							rules: [{
								required: true,
								message : 'Please enter your current password',
							}],
						})(
							<Input size={'large'} type={'password'}/>,
						)}
					</AntdForm.Item>

					<AntdForm.Item
						label="New password"
						{...validationErrors.new_password && {
							help          : validationErrors.new_password,
							validateStatus: 'error',
						}}
					>
						{getFieldDecorator('new_password')(
							<Input size={'large'} type={'password'}/>,
						)}
					</AntdForm.Item>

					<AntdForm.Item
						label="New password again"
						{...validationErrors.new_password_re && {
							help          : validationErrors.new_password_re,
							validateStatus: 'error',
						}}
					>
						{getFieldDecorator('new_password_re')(
							<Input size={'large'} type={'password'}/>,
						)}
					</AntdForm.Item>

					<Button type="primary" size={'large'} block onClick={this.props.onSubmit.bind(this)}>
						Update profile
					</Button>
				</AntdForm>
			</Spin>
		);
	}
}

const AntdFormWrapper = AntdForm.create({
	onValuesChange: (props: any, changedValues, allValues) => {
		props.onChange(allValues);
	},
	mapPropsToFields(props: any) {
		return {
			email: AntdForm.createFormField({
				...props.profile.data.email,
				value: props.profile.form.data.email || props.profile.data.email,
			}),

			token: AntdForm.createFormField({
				...props.profile.data.token,
				value: props.profile.data.token,
			}),

			username: AntdForm.createFormField({
				...props.profile.data.username,
				value: props.profile.data.username,
			}),

			current_password: AntdForm.createFormField({
				...props.profile.data.current_password,
				value: props.profile.form.data.current_password,
			}),

			new_password: AntdForm.createFormField({
				...props.profile.data.new_password,
				value: props.profile.form.data.new_password,
			}),

			new_password_re: AntdForm.createFormField({
				...props.profile.data.new_password_re,
				value: props.profile.form.data.new_password_re,
			}),
		};
	},
})(Form);

export interface Props {
	profile: ProfileState;
	update: (data: iModel) => void;
	formReset: () => void;
	formChange: (allValues: any) => void;
}

export class ProfileForm extends React.Component<Props> {
	formRef: any;

	componentDidMount(): void {
		const form = this.formRef.props.form;
		form.resetFields();
		this.props.formReset();
	}

	prepareValues(values: any) {
		const res: any = {};

		if (values.options) {
			for (let i = 0; i < values.options.length; i++) {
				res[values.options[i]] = true;
			}
		}

		delete values.options;

		return { ...res, ...values };
	}

	update() {
		const form = this.formRef.props.form;
		form.validateFields((err: string, values: string[]) => {
			if (!err) this.props.update(this.prepareValues(values));
		});
	}

	saveFormRef(formRef: any) {
		this.formRef = formRef;
	}

	formReset() {
		const form = this.formRef.props.form;
		form.resetFields();
	}

	render() {
		return (
			<AntdFormWrapper
				profile={this.props.profile}
				submitting={this.props.profile.form.submitting}
				wrappedComponentRef={this.saveFormRef.bind(this)}
				validationErrors={this.props.profile.form.errors}
				onSubmit={this.update.bind(this)}
				onChange={this.props.formChange.bind(this)}
			/>
		);
	}
}


const mapStateToProps = (state: RootState) => {
	return {
		profile: state.profile,
	};
};

const mapDispatchToProps = { update, formChange, formReset };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProfileForm);

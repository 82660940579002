import React from 'react';

import { Row, Col } from 'antd';

import DownloadCard from './download-card';
import UnixClientGuide from './unix-client-guide';
import { GuiWarn } from '@/components/pages/download/index';

interface Props {
	version: string;
}

export default ({ version }: Props) => {
	return (
		<div style={{ marginTop: 40 }}>
			<div style={{ marginBottom: 60 }}>
				<UnixClientGuide/>
			</div>

			<h2 style={{ fontWeight: 'bolder' }}>Desktop app (GUI)</h2>
			<GuiWarn/>

			<Row gutter={20}>
				<Col xs={24} md={24} lg={8} style={{ marginTop: 20, paddingTop: 20 }}>
					<b>Debian</b>
				</Col>

				<Col xs={24} md={24} lg={16}>
					<DownloadCard
						title={'dnsadblock.deb'}
						description={'DEB desktop app for Linux'}
						downloadUrl={`https://d3tzov1vemmztr.cloudfront.net/dnsadblock-desktop-${version}-amd64.deb`}
					/>
				</Col>

				<Col xs={24} md={24} lg={8} style={{ marginTop: 20, paddingTop: 20 }}>
					<b>RPM</b>
				</Col>

				<Col xs={24} md={24} lg={16}>
					<DownloadCard
						title={'dnsadblock.rpm'}
						description={'RPM desktop app for Linux (GUI)'}
						downloadUrl={`https://d3tzov1vemmztr.cloudfront.net/dnsadblock-desktop-${version}-x86_64.rpm`}
					/>
				</Col>

				<Col xs={24} md={24} lg={8} style={{ marginTop: 20, paddingTop: 20 }}>
					<b>Other distros</b>
				</Col>

				<Col xs={24} md={24} lg={16}>
					<DownloadCard
						title={'dnsadblock.tar.gz'}
						description={'Installer for our desktop app (GUI)'}
						downloadUrl={`https://d3tzov1vemmztr.cloudfront.net/dnsadblock-desktop-${version}-x64.tar.gz`}
					/>
				</Col>
			</Row>
		</div>
	);
};

import React from 'react';

import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';

import { Row, Col } from 'antd';

import DownloadCard from './download-card';

interface Props {
	version: string;
}

export default ({ version }: Props) => {
	return (
		<div style={{ marginTop: 40 }}>
			<Row gutter={20}>
				<Col xs={24} md={24} lg={4} style={{ marginTop: 20, paddingTop: 20 }}>
					<b>Android</b>
				</Col>

				<Col xs={24} md={24} lg={20}>
					<DownloadCard
						title={'Google play store'}
						description={'Android app on the Google Play store'}
						downloadUrl={`https://play.google.com/store/apps/details?id=com.dnsadblock.android`}
						inNewTab={true}
						disabled={false}
						icon={<AndroidOutlined key="android"/>}
					/>
				</Col>

				<Col xs={24} md={24} lg={4} style={{ marginTop: 20, paddingTop: 20 }}>
					<b>iOS</b>
				</Col>

				<Col xs={24} md={24} lg={20}>
					<DownloadCard
						title={'Apple store'}
						description={'iOS app on the Apple store'}
						downloadUrl={`https://apps.apple.com/ro/app/dnsadblock/id1570989093#?platform=iphone`}
						disabled={false}
						inNewTab={true}
						icon={<AppleOutlined key="apple"/>}
					/>
				</Col>
			</Row>
		</div>
	);
};

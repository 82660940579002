import * as React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';

import { State as iRootState } from '@/types';
import { iState as iStateLogs } from '@/state/models/logs';
import {
	filter,
	FILTER_COLUMN_TYPE,
	FILTER_TYPE_ALL,
	FILTER_TYPE_ALLOWED,
	FILTER_TYPE_BLOCKED,
} from '@/state/reducers/logs';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	logs: iStateLogs;

	filter: typeof filter;
}

const LogsFilterType: React.FunctionComponent<Props> = (props: Props) => {
	const { Option } = Select;
	const handleChange = (v: string) => {
		props.filter({ column: FILTER_COLUMN_TYPE, query: v });
	};

	const currentValue = (): string | undefined => {
		const curr = props.logs.data.filters.filter(f => f.column == FILTER_COLUMN_TYPE);
		return curr.length ? curr[0].query : undefined;
	};

	return (
		<Select
			style={{ width: '100%' }}
			placeholder={'Type'}
			size={'large'}
			value={currentValue()}
			showArrow={true}
			allowClear={true}
			filterOption={false}
			labelInValue={false}
			onChange={handleChange}
			notFoundContent={null}
		>
			<Option key={`filter-type-all`} value={FILTER_TYPE_ALL}>All</Option>
			<Option key={`filter-type-blocked`} value={FILTER_TYPE_BLOCKED}>Blocked</Option>
			<Option key={`filter-type-allowed`} value={FILTER_TYPE_ALLOWED}>Allowed</Option>
		</Select>
	);
};


const mapDispatchToProps = { filter };
const mapStateToProps = (state: iRootState) => ({
	logs: state.logs,
});
export default connect(mapStateToProps, mapDispatchToProps)(LogsFilterType);

import * as React from 'react';
import { connect } from 'react-redux';
import { truncate } from 'lodash';
import styled from 'styled-components';

import { NodeExpandOutlined } from '@ant-design/icons/lib';
import { List, Avatar, Tooltip, Card, Progress, Row, Col } from 'antd';

import { State as iRootState } from '@/types';
import { iState } from '@/state/models/device';
import { iState as iStateProfile } from '@/state/models/profile';
import { load, setSelected, toggleStatsDrawer } from '@/state/reducers/devices';
import { percentValFromTotal } from '@/utils';
import { load as loadStats } from '@/state/reducers/device_stats';

const ListItem = styled(List.Item)`
	.device_ip {
		
	}
	.device_id {
		color: var(--text-color-secondary);
		font-size: .8rem;
	}
	.device_model {
		color: var(--text-color-secondary);
		font-size: .8rem;
	}
	.ant-card-meta-detail > div:not(:last-child){
		margin-bottom: 0;
	}
	.ant-progress-text {
		font-size: .8rem;
	}
	.ant-progress {
		vertical-align: inherit;
    		padding-top: 3px;
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	profile: iStateProfile;
	devices: iState;

	load: typeof load;
	loadStats: typeof loadStats;
	setSelected: typeof setSelected;
	toggleStatsDrawer: typeof toggleStatsDrawer;
}

const DevicesList: React.FunctionComponent<Props> = (props: Props) => {
	React.useEffect(() => {
		if (!props.devices.data.loading && !props.devices.data.loaded) props.load();
	}, []);

	return (
		<List
			loading={props.devices.data.loading}
			dataSource={props.devices.data.results}
			grid={{
				gutter: 16,
				xs    : 1,
				sm    : 2,
				md    : 2,
				lg    : 3,
				xl    : 3,
				xxl   : 3,
			}}
			renderItem={item => {
				return (
					<ListItem>
						<Card
							hoverable={true}
							onClick={() => {
								props.setSelected(item);
								props.toggleStatsDrawer(true);
								props.loadStats();
							}}
							title={<div>
								{item.connection && (
									<Tooltip title={'mapped to configuration'}>
									<span>
										<NodeExpandOutlined style={{
											color: 'var(--color-primary)',
										}}/>
										&nbsp;
									</span>
									</Tooltip>
								)}
								<b>{truncate(item.device_name || 'unknown', {
									length  : 20,
									omission: '',
								})}</b>

								<div style={{ display: 'inline-block', float: 'right' }}>
									<Tooltip title={'local IP address'}>
										<span>{item.device_ip || 'unknown'}</span>
									</Tooltip>
								</div>
							</div>}
						>
							<Card.Meta
								avatar={<Avatar style={{
									backgroundColor: 'var(--color-primary)',
								}} size={'large'}>
									{truncate(item.device_name, {
										length  : 6,
										omission: '',
									})}
								</Avatar>}
								description={<Row gutter={8}>
									<Col xs={24} lg={12}>
										<Tooltip title={'device identifier'}>
											<div className={'device_id'}>{truncate(item.device_id,
												{
													length  : 8,
													omission: '',
												})}</div>
										</Tooltip>
									</Col>

									<Col xs={0} lg={12} style={{
										textAlign    : 'right',
										verticalAlign: 'top',
									}}>
										<Tooltip
											title={`Blocked requests: ${Number(item.block_count).toLocaleString()}`}>
											<Progress
												size="small"
												showInfo={false}
												percent={percentValFromTotal(
													item.block_count,
													item.block_count + item.allow_count,
												)}
												steps={35}
												strokeColor="var(--color-accent-secondary)"
											/>
										</Tooltip>
									</Col>

									<Col xs={24} lg={12}>
										<Tooltip title={'device model'}>
											<div className={'device_model'}>
												{item.device_model || 'unknown'}
											</div>
										</Tooltip>
									</Col>

									<Col xs={0} lg={12} style={{
										textAlign    : 'right',
										verticalAlign: 'top',
									}}>
										<Tooltip
											title={`Allowed requests: ${Number(item.allow_count).toLocaleString()}`}>
											<Progress
												size="small"
												showInfo={false}
												percent={percentValFromTotal(
													item.allow_count,
													item.block_count + item.allow_count,
												)} steps={35}
												strokeColor="var(--color-primary)"/>
										</Tooltip>
									</Col>
								</Row>}
							/>
						</Card>
					</ListItem>
				);
			}}
		/>
	);
};


const mapDispatchToProps = { load, setSelected, toggleStatsDrawer, loadStats };
const mapStateToProps = (state: iRootState) => ({
	profile: state.profile,
	devices: state.devices,
});
export default connect(mapStateToProps, mapDispatchToProps)(DevicesList);

import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Empty, Dropdown, Avatar, Menu, Skeleton, message } from 'antd';
import { IBrowser } from 'redux-responsive';

import { t } from '@/utils';
import { State as iRootState } from '@/types';
import { iState as iModelState, iModel } from '@/state/models/logs';
import {
	unixToDateTime,
	ruleFavIcon,
	protocolStr,
	prepareFormValues,
	getSubdomain,
	copyTextToClipboard,
} from '@/utils';
import { iState as iRuleCategoriesState, iModel as iModelCategory } from '@/state/models/rule_categories';
import { create as createRule } from '@/state/reducers/rules';
import { getAllowed as getAllowedCategory, getBlocked as getBlockedCategory } from '@/state/selectors/rule_categories';
import RuleType from '../rule-type';

const List = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
	font-family: monospace, monospace, Courier, "Ubuntu Mono", "Roboto Mono";
`;

const ListItem = styled.li`
	font-size: .75rem;
	border-bottom: 1px solid #f8f8f8;
	padding-top: 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	
	span {
		margin-left: 5px;
		&:first-of-type{
			margin-left: 0;
		}
	}
	
	.name {
		color: ${(props: { item: iModel }) => isOfTypeBlocked(props.item) ? 'red' : 'black'};
		margin-right: 5px;
		font-size: .9rem;
		cursor: pointer;
		font-weight: bold;
		
		&:hover {
			border-bottom: 1px solid black;
		}
	}
	
	.qtype, .date {
		color: #ccc;
	}
	
	.date {
		float: right;
	}
	
	.connection {
		color: rosybrown;
		margin-left: 5px;
	}
	
	.device {
		color: dodgerblue;
		margin-left: 5px;
	}
	
	.protocol {
		color: #ccc;
	}
	
	.ant-tag {
		margin: 0;
		padding: 2px 5px 0 5px;
		line-height: 16px;
		font-size: .75rem;
	}
`;

const isOfTypeBlocked = (log: iModel): boolean => log.type == -1;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	browser: IBrowser;
	logs: iModelState;
	rule_categories: iRuleCategoriesState;
	allowed_category: iModelCategory | null;
	blocked_category: iModelCategory | null;

	createRule: typeof createRule;
}

const LogsList: React.FunctionComponent<Props> = (props: Props) => {
	const ruleFromName = (name: string): string => {
		const hasSubdomain = getSubdomain(name) != '';
		return hasSubdomain ? name : `||${name}`;
	};

	const blacklist = (log: iModel, forAccount: boolean = false) => {
		if (props.blocked_category != null) {
			props.createRule(prepareFormValues({
				rule       : ruleFromName(log.qname.name),
				category   : props.blocked_category.id,
				connections: forAccount ? [] : [log.connection.id],
			}));

			setTimeout(() => message.success(t('logs.rule_added')), 1000);
		} else {
			message.error(t('logs.cant_find_cat'));
		}
	};

	const whitelist = (log: iModel, forAccount: boolean = false) => {
		if (props.allowed_category != null && log.rule?.rule) {
			props.createRule(prepareFormValues({
				rule       : log.rule.rule,
				category   : props.allowed_category.id,
				connections: forAccount ? [] : [log.connection.id],
			}));

			setTimeout(() => message.success(t('logs.rule_added')),
				1000);
		} else {
			message.error(t('logs.cannot_add'));
		}
	};

	const nameRenderer = (log: iModel) => {
		return (
			<Dropdown trigger={['click']} overlay={
				<Menu>
					{isOfTypeBlocked(log) && (
						<Menu.SubMenu title="Whitelist" key={'11'}>
							<Menu.Item onClick={() => whitelist(log)}>For connection</Menu.Item>
							<Menu.Item onClick={() => whitelist(log, true)}>For entire account</Menu.Item>
						</Menu.SubMenu>
					)}

					{!isOfTypeBlocked(log) && (
						<Menu.SubMenu title="Blacklist" key={'22'}>
							<Menu.Item onClick={() => blacklist(log)}>For connection</Menu.Item>
							<Menu.Item onClick={() => blacklist(log, true)}>For entire account</Menu.Item>
						</Menu.SubMenu>
					)}

					<Menu.Divider/>

					<Menu.Item key="0" onClick={(p: any) => {
						p.domEvent.preventDefault();
						copyTextToClipboard(log.qname.name);
						message.success('name was copied to your clipboard');
					}}>
						Copy to clipboard
					</Menu.Item>
					<Menu.Item key="33">
						<a target="_blank" rel="noopener noreferrer" href={`https://${log.qname.name}`}>
							Visit
						</a>
					</Menu.Item>
				</Menu>
			}>
				<a className="name ant-dropdown-link" onClick={e => e.preventDefault()}>{log.qname.name}</a>
			</Dropdown>
		);
	};

	if (props.logs.data.loading) return <Skeleton paragraph={{ rows: 24 }}/>;

	return props.logs.data.results.length > 0 ? (
		<List>
			{props.logs.data.results.map((log) => {
				return <ListItem item={log} key={log.id}>
					<Avatar size={14} src={ruleFavIcon(log.qname.name)}/>
					&nbsp;

					{nameRenderer(log)}

					{props.browser.greaterThan.small && log.type == -1 && log.rule != null && (
						<RuleType text category={props.rule_categories.data.results.filter(
							(rg) => rg.id == log.rule.category,
						)[0]}/>
					)}

					{props.browser.greaterThan.small && <span className={'qtype'}>{log.qtype}</span>}

					{props.browser.greaterThan.small && (
						<b className={'connection'}>[{log.connection.alias}]</b>
					)}

					{props.browser.greaterThan.small && (<span className={'protocol'}>
						{protocolStr(log.protocol)}
					</span>)}

					{log.device && props.browser.greaterThan.small && (<b className={'device'}>
						{log.device.device_name}
					</b>)}

					{props.browser.greaterThan.medium && (
						<span className={'date'}>{unixToDateTime(log.created)}</span>
					)}
				</ListItem>;
			})}
		</List>
	) : <Empty/>;
};


const mapDispatchToProps = { createRule };
const mapStateToProps = (state: iRootState) => ({
	logs            : state.logs,
	browser         : state.browser,
	rule_categories : state.rule_categories,
	allowed_category: getAllowedCategory(state),
	blocked_category: getBlockedCategory(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(LogsList);

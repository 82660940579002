import * as React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button, Input, List, Skeleton, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { selectCopyOnFocus, t } from '@/utils';
import { State as iRootState } from '@/types';
import { Model as iModelConnection, State as iConnectionsState } from '@/state/models/connection';
import { update as updateConnection } from '@/state/reducers/connections';
import { getExpanded } from '@/state/selectors/connections';
import { connectionsUrl, knowledgebaseArticles, myIpUrl } from '@/routes';


interface group {
	key: string;
	title: string;
	info: any;
	description?: any;
}

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	connection: iModelConnection;
	connections: iConnectionsState;
	updateConnection: typeof updateConnection;
}

const AuthenticationInstructions: React.FunctionComponent<Props> = (props: Props) => {
	const [myIpAddres, setMyIpAddres] = React.useState('');
	const [localConnectionIp, setLocalConnectionIp] = React.useState('');
	const [dynamicdnsHostname, setDynamicdnsHostname] = React.useState('');

	React.useEffect(() => {
		setDynamicdnsHostname(props.connection.dynamicdns_hostname);
		axios.get(myIpUrl).then((response) => {
			setMyIpAddres(response.data.ip);
		});
	}, []);

	const groups: group[] = [{
		title: 'Allowed IP address',
		key  : 'allowed_ip',
		info : (record: iModelConnection) => {
			const newIp = (): string => {
				return localConnectionIp || record.ip_address;
			};

			return <div>
				<Input.Group compact size={'large'}>
					<Tooltip title="Fill with your current ip">
						<Button
							size={'large'}
							type={'ghost'}
							style={{
								width         : 50,
								justifyContent: 'center',
							}}
							icon={<ArrowRightOutlined/>}
							onClick={() => setLocalConnectionIp(myIpAddres)}
						/>
					</Tooltip>
					<Input
						value={newIp()}
						style={{ fontWeight: 600, width: 'calc(100% - 150px)' }}
						onChange={(e: any) => setLocalConnectionIp(e.target.value)}
					/>
					<Button
						size={'large'}
						disabled={record.ip_address == newIp()}
						style={{ width: 100 }}
						type={'primary'}
						onClick={() => props.updateConnection(Object.assign({}, record, {
							ip_address: newIp(),
						}))}
					>Update</Button>
				</Input.Group>
				<br/>
				<p>
					{t('connections.info_allowed_ip')}
					&nbsp;<a
					href={knowledgebaseArticles.allowedIpWhy}
					target={'_blank'}
				>More</a>
				</p>
			</div>;
		},
	}, {
		title: 'Dynamic DNS hostname',
		key  : 'dynamicdns_hostname',
		info : (record: iModelConnection) => {
			return <div>
				<Input.Group compact size={'large'}>
					<Input
						value={dynamicdnsHostname}
						style={{ fontWeight: 600, width: 'calc(100% - 100px)' }}
						onChange={(e: any) => setDynamicdnsHostname(e.target.value)}
					/>
					<Button
						size={'large'}
						disabled={dynamicdnsHostname == record.dynamicdns_hostname}
						style={{ width: 100 }}
						type={'primary'}
						onClick={() => props.updateConnection(Object.assign({}, record, {
							dynamicdns_hostname: dynamicdnsHostname,
						}))}
					>Update</Button>
				</Input.Group>
				<br/>
				<p>
					{t('connections.dynamicdns_hostname')}
					&nbsp;<a
					href={knowledgebaseArticles.updateDynamicIpHowto}
					target={'_blank'}
				>More</a>
				</p>
			</div>;
		},
	}, {
		title: 'API update Ip url',
		key  : 'update_url',
		info : (record: iModelConnection) => <div>
			<Input
				size={'large'}
				value={`${connectionsUrl}${record.id}/<new-ip-address>/`}
				style={{ fontWeight: 600 }}
				onFocus={selectCopyOnFocus}
			/>

			<p>{t('connections.info_update_url')}</p>

			<p>
				{t('connections.info_update_url2')} <a
				href={knowledgebaseArticles.updateDynamicIpHowto}
				target={'_blank'}>More</a>
			</p>
		</div>,
	}];

	return (
		<List
			size={'small'}
			loading={props.connections.data.loading}
			itemLayout="horizontal"
			bordered={false}
			dataSource={[props.connection]}
			renderItem={(item: iModelConnection) => groups.map((group: any, i: number) => {
				return <List.Item key={group.key} actions={[]}>
					<List.Item.Meta title={group.title} description={group.info(item)}/>
				</List.Item>;
			})}
		/>
	);
};


const mapDispatchToProps = { updateConnection };
const mapStateToProps = (state: iRootState) => ({
	connection : getExpanded(state),
	connections: state.connections,
});
export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationInstructions);

import * as React from 'react';
import { connect } from 'react-redux';

import { State as iRootState } from '@/types';
import { Card, Col, Row } from 'antd';
import WireguardQr from '@/components/wireguard-qr';
import { Model as iModelConnection } from '@/state/models/connection';
import { getExpanded } from '@/state/selectors/connections';
import { iState as iStateProfile } from '@/state/models/profile';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	profile: iStateProfile;
	connection: iModelConnection;
}

const MobilePhones: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Row>
			<Col xs={24} md={24} lg={14} style={{ marginTop: 20, marginBottom: 20 }}>
				<Card
					style={{ border: 'none', padding: 0 }}
				>
					<Card.Meta
						title="Connecting from mobile using our app?"
						description='Scan this QR code for automatic connection. For details about downloading the mobile app please visit the &quot;Downloads&quot; page.'
					/>
				</Card>
			</Col>
			<Col
				xs={24} md={24} lg={10}
				style={{ marginTop: 20, marginBottom: 20, textAlign: 'center', padding: "0 20px" }}
			>
				{props.connection.server && (
					<WireguardQr
						cId={props.connection.id}
						cIdentifier={props.connection.identifier}
						token={props.profile.data.token}
						style={{ float: 'right', border: '1px solid #ddd', width: "100%" }}
					/>
				)}
			</Col>
		</Row>
	);
};


const mapDispatchToProps = {};
const mapStateToProps = (state: iRootState) => ({
	connection: getExpanded(state),
	profile   : state.profile,
});
export default connect(mapStateToProps, mapDispatchToProps)(MobilePhones);

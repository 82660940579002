import * as React from 'react';
import { connect } from 'react-redux';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Button, Col, Divider, Input, InputNumber, message, Row, Select, Spin } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';

import { State as RootState } from '@/types';
import { iState as iRulesState, iModel as iRulesModel } from '@/state/models/rules';
import { create, formChange, formReset } from '@/state/reducers/rules';
import { iState as iRuleCategoriesState, TYPE_BLOCK } from '@/state/models/rule_categories';
import { State as iConnectionsState } from '@/state/models/connection';
import { prepareFormValues, t } from '@/utils';


interface Props extends FormComponentProps {
	rules: iRulesState;
	rule_categories: iRuleCategoriesState;
	connections: iConnectionsState;
	style?: any;
	create: (data: iRulesModel) => void;
	formChange: (allValues: any) => void;
	formReset: () => void;
}

const RulesForm = Form.create({
	name: 'rules_form',
	mapPropsToFields(props: any) {
		return {
			rule       : Form.createFormField({
				...props.rules.form.data.rule,
				value: props.rules.form.data.rule,
			}),
			category   : Form.createFormField({
				...props.rules.form.data.category,
				value: props.rules.form.data.category,
			}),
			connections: Form.createFormField({
				...props.rules.form.data.connections,
				value: props.rules.form.data.connections,
			}),
			age_min    : Form.createFormField({
				...props.rules.form.data.age_min,
				value: props.rules.form.data.age_min,
			}),
		};
	},
	onValuesChange(props: any, values) {
		props.formChange(values);
	},
})((props: Props) => {
	const { rule_categories, form, create, rules, connections } = props;
	const { getFieldDecorator } = form;

	const handleCreate = () => {
		form.validateFields((err: string, values: string[]) => {
			if (err) {
				return;
			}
			create(prepareFormValues(values));
			message.success(t('logs.rule_added'));
		});
	};

	const validateRule = (rule: any, value: string, callback: any) => {
		callback();
	};

	const getErr = (field: string): any => {
		return hasErr(field) ? {
			help          : rules.form.errors[field],
			validateStatus: 'error',
		} : {};
	};

	const hasErr = (field: string): boolean => {
		return rules.form.errors.hasOwnProperty(field);
	};

	const ageMinDisplay = (): string => {
		const selectedCategory = form.getFieldValue('category');
		const category = rule_categories.data.results.filter((rg) => rg.id == selectedCategory)[0];
		return category && category.type == TYPE_BLOCK ? 'block' : 'none';
	};

	return (
		<Spin tip={'Creating rules'} spinning={false}>
			<Form layout="vertical" onSubmit={handleCreate}>
				<Row gutter={40}>
					<Col lg={12} xs={24}>
						<Form.Item
							label="Hostname matching rules"
							{...getErr('rule')}
							extra={<div>
								<p>Each rule on a separate line. No more than 1,000 rules in total for each submission.</p>

								<p>
									<i>Example:</i><br/>
									<b>||domain.com</b> will match domain.com and www.domain.com<br/>
									<b>*domain.com</b> will match domain.com and any subdomain of
									domain.com
								</p>
							</div>}
						>
							{getFieldDecorator('rule', {
								rules: [
									{
										required: true,
										message : 'Please enter some rules',
									}, {
										validator: validateRule,
									}],
							})(
								<Input.TextArea rows={12}/>,
							)}
						</Form.Item>
					</Col>

					<Col lg={12} xs={24}>
						<Form.Item
							label="Rules category"
							className="collection-create-form_last-form-item"
							{...getErr('category')}
						>
							{getFieldDecorator('category', {
								rules: [{ required: true, message: 'Please enter a rule category' }],
							})(
								<Select placeholder="Select a category">
									{rule_categories.data.results.map((rg) => <Select.Option
											key={`rform-rg-${rg.id}`}
											value={rg.id}
										>
											{rg.name}
										</Select.Option>,
									)}
								</Select>,
							)}
						</Form.Item>

						<Form.Item
							label="Required age"
							{...getErr('age_min')}
							extra={`If you want to create age based restrictions enter an age here.
									Leave 0 for no age restriction`}
							style={{
								//	if the selected group is of allow type we hide this input
								display: ageMinDisplay(),
							}}
						>
							{getFieldDecorator('age_min', {
								initialValue: 0,
								rules       : [{
									required: false,
									message : 'Age must be between 1 and 18',
								}],
							})(
								<InputNumber min={0} max={18}/>,
							)}
						</Form.Item>

						<Form.Item
							label="Affected connections"
							className="collection-create-form_last-form-item"
							{...getErr('connections')}
							extra={`Leave empty to affect all connections`}
						>
							{getFieldDecorator('connections', {})(
								<Select
									placeholder="Select connections"
									mode="multiple"
								>
									{connections.data.results.map((rg) => <Select.Option
											key={`rform-rg-${rg.id}`}
											value={rg.id}
										>
											{rg.alias}
										</Select.Option>,
									)}
								</Select>,
							)}
						</Form.Item>
					</Col>
				</Row>

				<Divider/>

				<Button type="primary" onClick={handleCreate}>Submit</Button>
			</Form>
		</Spin>
	);
});


const mapStateToProps = (state: RootState) => {
	return {
		rules          : state.rules,
		connections    : state.connections,
		rule_categories: state.rule_categories,
	};
};

const mapDispatchToProps = { create, formChange, formReset };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RulesForm);

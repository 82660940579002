import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { IBrowser } from 'redux-responsive';

import { Descriptions, Form, Skeleton, Spin } from 'antd';
import { State as iRootState } from '@/types';
import { iState as iServersState } from '@/state/models/servers';
import { State as iConnectionsState } from '@/state/models/connection';
import { Model as iConnectionModel } from '@/state/models/connection';
import { getExpanded } from '@/state/selectors/connections';
import { formFieldError, t } from '@/utils';
import { load as loadServers } from '@/state/reducers/servers';
import { update } from '@/state/reducers/connections';
import { ping as pingServer } from '@/utils';
import ServerSelector from '@/components/connections/servers/selector';

const Wrapper = styled(Descriptions)`
	.ant-descriptions-view {
		border-radius: 0;
	}

	.ping-skeleton {
		h3 {
			margin: 0;
		}
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	browser: IBrowser;
	update: typeof update;
	servers: iServersState;
	connection: iConnectionModel;
	connections: iConnectionsState;
	loadServers: typeof loadServers;
}

const GeneralInfo: React.FunctionComponent<Props> = (props: Props) => {
	const { connection, connections, servers, browser, update, ...rest } = props;
	const { validationErrors } = connections;

	const [ping, setPing] = React.useState(0);
	const [pingLoading, setPingLoading] = React.useState(false);
	const [form] = Form.useForm();

	React.useEffect(() => {
		props.loadServers();

		setPingLoading(true);
		setTimeout(() => {
			// we ping twice to "warmup" our server on our DOH endpoint
			pingServer('https://' + connection.server?.fqdn + '?name=dnsadblock.com')
				.then(((res: number) => {
					setPingLoading(false);
					pingServer('https://' + connection.server?.fqdn + '?name=dnsadblock.com')
						.then(((res: number) => setPing(res))).catch(console.error);
				})).catch(console.error);
		}, 500);
	}, []);

	return (
		<Spin spinning={connections.data.loading} tip={'Updating connection...'}>
			<Form
				form={form}
				layout="vertical"
				initialValues={{
					server__id: connection.server?.id,
				}}
			>
				<Wrapper
					column={{ xs: 1, sm: 1, md: 1, lg: 1 }}
					bordered
					size={'small'}
					layout={browser.lessThan.medium ? 'vertical' : 'horizontal'}
					{...rest}
				>
					<Descriptions.Item label="Connection Id">
						<b>{connection.id}</b>
					</Descriptions.Item>
					<Descriptions.Item label="Server Id">
						<b>{connection.server ? connection.server.id : ''}</b>
					</Descriptions.Item>

					<Descriptions.Item label="DNS server (IPv4)">
						<b>{connection.server ? connection.server.ipv4 : ''}</b>
					</Descriptions.Item>

					<Descriptions.Item label="DNS server (IPv6)">
						<b>{connection.server ? connection.server.ipv6 : ''}</b>
					</Descriptions.Item>

					<Descriptions.Item label="DOT address">
						<b>{connection.server ? connection.server.fqdn : ''}</b>
					</Descriptions.Item>

					<Descriptions.Item label="DOH address">
						<b>{connection.server ? `https://${connection.server.fqdn}/${connection.identifier}` : ''}</b>
					</Descriptions.Item>

					<Descriptions.Item label="Ping">
						<Skeleton
							className={'ping-skeleton'}
							loading={pingLoading}
							title={true}
							paragraph={false}
						>
							<b>{ping}</b>ms
						</Skeleton>
					</Descriptions.Item>

					<Descriptions.Item label="Location">
						<Form.Item
							{...formFieldError('location', validationErrors)}
							name="server__id"
							style={{ margin: 0 }}
							rules={[{
								required: true,
								message : t('misc.form.validation_required'),
							}]}
						>
							<ServerSelector onSelect={(value: number) => {
								form.setFieldsValue({ server__id: value });
								form.validateFields().then((values: any) => {
									props.update(Object.assign({}, connection, values));
								}).catch((reason: any) => console.log(reason.errorFields));
							}}/>
						</Form.Item>
					</Descriptions.Item>
				</Wrapper>
			</Form>
		</Spin>
	);
};


const mapDispatchToProps = { loadServers, update };
const mapStateToProps = (state: iRootState) => ({
	browser    : state.browser,
	servers    : state.servers,
	connections: state.connections,
	connection : getExpanded(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);

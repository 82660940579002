import * as React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Form, Input } from 'antd';

import { myIpUrl } from '@/routes';
import { formFieldError, t } from '@/utils';
import { State as iRootState } from '@/types';
import { load as loadServers } from '@/state/reducers/servers';
import { create, update } from '@/state/reducers/connections';
import { State as StateConnections } from '@/state/models/connection';
import ServerSelector from '@/components/connections/servers/selector';

export interface Signals {
	reset: boolean;
	submit: boolean;
}

interface Props {
	connections: StateConnections;
	// This form has no button controls so we use signals to receive these events
	signals: Signals;
	create: typeof create;
	update: typeof update;
	loadServers: typeof loadServers;
}

const ConnectionsForm: React.FunctionComponent<Props> = (props: Props) => {
	const [form] = Form.useForm();
	const { connections }: Props = props;
	const { validationErrors } = connections;

	React.useEffect(() => {
		props.loadServers();
	}, []);

	React.useEffect(() => {
		form.resetFields();
	}, [props.signals.reset]);

	React.useEffect(() => {
		if (!props.signals.submit) return;
		if (props.signals.submit) form.validateFields().then((values: any) => {
			form.resetFields();

			axios.get(myIpUrl).then((response: any) => {
				props.create(Object.assign({}, values, {
					ip_address: response.data.ip,
				}));
			});

		}).catch((reason: any) => console.log(reason.errorFields));
	}, [props.signals.submit]);

	return (
		<Form
			form={form}
			layout="vertical"
			initialValues={{
				alias     : 'Home',
				age_min   : 0,
				server__id: null,
			}}
		>
			<Form.Item
				label={t('connections.form.alias')}
				extra={t('connections.form.alias_def')}
				{...formFieldError('alias', validationErrors)}
			>
				<Form.Item
					name="alias"
					noStyle
					rules={[{ required: true, message: t('misc.form.validation_required') }]}
				>
					<Input/>
				</Form.Item>
			</Form.Item>

			<Form.Item
				label={t('connections.form.server')}
				extra={t('connections.form.server_def')}
				{...formFieldError('location', validationErrors)}
			>
				<Form.Item
					name="server__id"
					noStyle
					rules={[{ required: true, message: t('misc.form.validation_required') }]}
				>
					<ServerSelector onSelect={(v: number) => form.setFieldsValue({ server__id: v })}/>
				</Form.Item>
			</Form.Item>
		</Form>
	);
};


const mapDispatchToProps = {
	loadServers, create, update,
};
const mapStateToProps = (state: iRootState) => ({
	connections: state.connections,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionsForm);

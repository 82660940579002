import React from 'react';
import styled from 'styled-components';

import SEO from '../seo';
import StandaloneFormPage from '../standaloneFormPage';

import Form from '../forgot_password/update_form';
import Logo from '../logo';
import LoginBg from '@/static/images/login-bg.svg';

export const StandalonePage = styled(StandaloneFormPage)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-image: url(${LoginBg});
	background-repeat: no-repeat;
	background-position: center 110px;
	background-size: 100%;
	background-color: #f0f2f5;
	
	h2 {
		text-align: center;
	}
	
	.form {
		max-width: 400px;
		min-width: 400px;
	}
    
	@media (max-width: 700px) {
		.form {
			max-width: 100%;
			min-width: auto;
		}
	}
`;

export const LogoWrapper = styled.div`
	border-radius : 10%;
	padding      : 20px;
	width        : 140px;
	height       : 140px;
	vertical-align: middle;
	margin       : 0 auto 20px auto;
	text-align: center;
`;

interface Props {
	path?: string;
	token?: string;
	uid?: string;
}

const RecoverPasswordConfirm: React.FunctionComponent<Props> = (props: Props) => {
	const { token, uid, ...rest } = props;
	return <StandalonePage>
		<SEO title="Change your password"/>
		<LogoWrapper>
			<Logo width={90} style={{ verticalAlign: 'middle', marginLeft: 3, marginTop: 2 }}/>
		</LogoWrapper>
		<h2>Set a new password</h2>
		<Form className={'form'} token={token} uid={uid}/>
	</StandalonePage>;
};

export default RecoverPasswordConfirm;

import * as React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';

import { CheckOutlined, DownOutlined } from '@ant-design/icons';

import { List, Button, Dropdown, Menu, Tag, Modal } from 'antd';

import { unixToDate, t } from '../../utils';
import { State as RootState } from '../../types';
import { iModel as iPlansModel, iState as iPlansState } from '../../state/models/plans';
import { iState as iProfileState } from '../../state/models/profile';
import { show as showPayment, cancelCurrentSubscription } from '../../state/reducers/payment';

const Wrapper = styled(List)`
	.ant-list-item-meta-content {
		flex: 1;
	}
	
	background: white;
	
	.ant-list-item {
		padding: 20px;
		
		&.active {
			background: rgba(247, 253, 247, 0.97)!important;
			border-radius: 6px;
		}
	}
`;

interface Props {
	plans: iPlansState;
	profile: iProfileState;
	showPayment: typeof showPayment;
	cancelCurrentSubscription: typeof cancelCurrentSubscription;
}

interface State {
	selectedPlan?: iPlansModel;
	selectedCycle?: 'monthly' | 'bi-annually' | 'annually';
}

export class PlanPick extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	state = {
		selectedPlan : undefined,
		selectedCycle: undefined,
	};

	subscribedIndicatorRender(record: iPlansModel): React.ReactNode {
		return this.planIsCurrent(record) ?
			<Button
				key={`btn-plan-${record.id}`}
				type="primary"
				shape="circle"
				icon={<CheckOutlined/>}
				size={'large'}
				ghost={true}
				style={{
					color      : '#52c41a',
					borderColor: '#52c41a',
				}}
			/> :
			<Button
				key={`btn-plan-${record.id}`}
				type="dashed"
				shape="circle"
				icon={<CheckOutlined/>}
				size={'large'}
				ghost={true}
				style={{
					color      : '#ccc',
					borderColor: '#ccc',
				}}
			/>;
	}

	showPayment(plan: iPlansModel, cycle: 'monthly' | 'bi-annually' | 'annually') {
		this.props.showPayment(plan, cycle);
	}

	selectPlan(plan: iPlansModel, cycle: 'monthly' | 'bi-annually' | 'annually'): void {
		const self = this;

		self.setState({
			selectedPlan : plan,
			selectedCycle: cycle,
		}, () => self.showPayment(plan, cycle));
	}


	cancelPlan() {
		const self = this;

		Modal.confirm({
			title  : t('payments.plan_cancel_confirm_title'),
			content: t('payments.plan_cancel_confirm_body_subscribed'),
			onOk() {
				self.props.cancelCurrentSubscription();
			},
		});
	}

	currentPlan() {

	}

	subscribeBtnRender(plan: iPlansModel): React.ReactNode {
		const self = this;
		const { profile } = this.props;
		const isCurrent = self.planIsCurrent(plan);
		const hasSubscription = profile.data.has_active_subscription;

		const planCycleMenu = (
			<Menu>
				{(isCurrent && hasSubscription) && (
					<Menu.Item
						key={`plan-${plan.id}-cancel`}
						style={{ color: 'red' }}
						onClick={() => self.cancelPlan()}
					>
						Cancel subscription
					</Menu.Item>
				)}

				<Menu.Item
					onClick={() => self.selectPlan(plan, 'monthly')}
					key={`plan-${plan.id}-monthly`}
					disabled={profile.data.subscription_cycle === 1 && isCurrent && hasSubscription}
				>
					{Number(plan.price_monthly).toLocaleString('en-US', {
						currency             : 'USD',
						style                : 'currency',
						currencyDisplay      : 'symbol',
						minimumFractionDigits: 2,
					})} monthly
				</Menu.Item>

				<Menu.Item
					onClick={() => self.selectPlan(plan, 'bi-annually')}
					key={`plan-${plan.id}-bi-annually`}
					disabled={profile.data.subscription_cycle === 2 && isCurrent && hasSubscription}
				>
					{Number(plan.price_bi_annually).toLocaleString('en-US', {
						currency             : 'USD',
						style                : 'currency',
						currencyDisplay      : 'symbol',
						minimumFractionDigits: 2,
					})} bi-annually
				</Menu.Item>

				<Menu.Item
					onClick={() => self.selectPlan(plan, 'annually')}
					key={`plan-${plan.id}-annually`}
					disabled={profile.data.subscription_cycle === 3 && isCurrent && hasSubscription}
				>
					{Number(plan.price_annually).toLocaleString('en-US', {
						currency             : 'USD',
						style                : 'currency',
						currencyDisplay      : 'symbol',
						minimumFractionDigits: 2,
					})} annually
				</Menu.Item>
			</Menu>
		);

		return (
			<Dropdown trigger={['click']} key={`btn-plan-${plan.id}`} overlay={planCycleMenu}>
				<Button
					type="default"
					size={'large'}
					style={{
						color      : '#52c41a',
						borderColor: '#52c41a',
					}}
				>{(isCurrent && hasSubscription) ? 'Options' : 'Subscribe'}
					<DownOutlined/></Button>
			</Dropdown>
		);
	}

	planIsCurrent(record: iPlansModel) {
		const { profile } = this.props;
		return record.id === profile.data.subscription_plan && profile.data.is_subscribed;
	}

	isFree(plan: iPlansModel) {
		//	yes yes *1 because our price may sit as a string
		return plan.price_monthly * 1 === 0;
	}

	render() {
		const { plans, profile } = this.props;
		const hasSubscription = profile.data.has_active_subscription;

		return <Wrapper
			size={'small'}
			bordered
			loading={plans.data.loading}
			itemLayout="horizontal"
			dataSource={plans.data.results}
			rowKey={(record: any) => record.id}
			renderItem={(item: any) => {
				return <List.Item
					actions={this.isFree(item) ? [] : [this.subscribeBtnRender(item)]}
					className={(this.planIsCurrent(item) && profile.data.is_subscribed) ? 'noselect active' : 'noselect'}
				>
					<List.Item.Meta
						avatar={[this.subscribedIndicatorRender(item)]}
						title={<div>
							<b>{item.name}</b>

							{this.planIsCurrent(item) && this.isFree(item) && (
								<Tag
									style={{ marginLeft: 10 }}
									color="purple"
								>FREE</Tag>
							)}

							{this.planIsCurrent(item) && !this.isFree(item) && (
								<Tag
									style={{ marginLeft: 10 }}
									color="purple"
								>Exp: <b>{unixToDate(profile.data.subscription_ends || 0)}</b>
								</Tag>
							)}

							{(this.planIsCurrent(item) && !hasSubscription && !this.isFree(item)) && (
								<Tag color="red">cancelled</Tag>
							)}
						</div>}
						description={item.description}
					/>
				</List.Item>;
			}}
		/>
			;
	}
}

const mapStateToProps = (state: RootState) => {
	return {
		plans  : state.plans,
		profile: state.profile,
	};
};

const mapDispatchToProps = { showPayment, cancelCurrentSubscription };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PlanPick);

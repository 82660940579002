import * as React from 'react';
import { connect } from 'react-redux';
import { IBrowser } from 'redux-responsive';
import styled from 'styled-components';

import { PageHeader, Layout } from 'antd';
import { PageHeaderProps } from 'antd/lib/page-header';

import AppNav from '../nav/app';
import Content from '../content';

import BaseLayout from './base';
import { State as RootState } from '@/types';

const NavWrapper = styled(Layout.Header)`
	padding   : 0;
	z-index: 1;
`;

const TitleWrapper = styled.div`
	background-color: white!important;
	padding: 10px 0 8px 0;
	border-bottom: 1px solid #f0f0f0;
`;

interface Props {
	titleProps: PageHeaderProps;
	browser: IBrowser;
}

interface State {

}

export class AppLayout extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		const { browser, titleProps, children } = this.props;
		const padding = browser.lessThan.small ? '10px' : '24px';

		return <BaseLayout>
			<Layout style={{ minHeight: '100%', paddingBottom: 70 }}>
				<NavWrapper>
					<AppNav/>
				</NavWrapper>

				{titleProps && (
					<TitleWrapper>
						<Content style={{ background: 'white' }}>
							<PageHeader {...titleProps}/>
						</Content>
					</TitleWrapper>
				)}

				<Layout>
					<Content padding={padding}>
						{children}
					</Content>
				</Layout>
			</Layout>
		</BaseLayout>;
	}
}

const mapStateToProps = (state: RootState) => {
	return {
		browser: state.browser,
	};
};

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AppLayout);

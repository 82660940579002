import React from 'react';
import { Router } from '@reach/router';
import { message } from 'antd';
import axios from 'axios';

import Login from '../components/pages/login';
import Register from '../components/pages/register';
import ForgotPassword from '../components/pages/forgot_password';
import RecoverPasswordConfirm from '../components/pages/recover_password_confirm';
import Dashboard from '../components/pages/dashboard';
import Devices from '../components/pages/devices';
import Connections from '../components/pages/connections';
import Filters from '../components/pages/filters';
import Profile from '../components/pages/profile';
import Billing from '../components/pages/billing';
import FilterLogs from '../components/pages/filterlogs';
import Download from '../components/pages/download';

import PrivateRoute from '../components/private-route';
import { iState as StateAuth } from '../state/models/auth';
import { State as RootState } from '../types';
import { connect } from 'react-redux';
import { readynessUrl } from '@/routes';

interface Props {
	auth: StateAuth;
}

export class App extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, RootState> {
	componentDidMount() {
		window.addEventListener('sw.offline', () => {
			message.warning('App is offline!');
		});

		// do a dummy request to a http endpoint that sets the csrf cookie
		// since we need that for a number of endpoints
		axios.get(readynessUrl, {
			withCredentials: true,
		}).catch(console.error);
	}

	render() {
		return <Router>
			<PrivateRoute auth={this.props.auth} path="/app/dashboard" component={Dashboard}/>
			<PrivateRoute auth={this.props.auth} path="/app/filters" component={Filters}/>
			<PrivateRoute auth={this.props.auth} path="/app/connections" component={Connections}/>
			<PrivateRoute auth={this.props.auth} path="/app/devices" component={Devices}/>
			<PrivateRoute auth={this.props.auth} path="/app/stats" component={Dashboard}/>
			<PrivateRoute auth={this.props.auth} path="/app/profile" component={Profile}/>
			<PrivateRoute auth={this.props.auth} path="/app/billing" component={Billing}/>
			<PrivateRoute auth={this.props.auth} path="/app/logs" component={FilterLogs}/>
			<PrivateRoute auth={this.props.auth} path="/app/download" component={Download}/>

			<Login path="/app/login"/>
			<Register path="/app/register"/>
			<ForgotPassword path="/app/forgot-password"/>
			<RecoverPasswordConfirm path="/app/recover-password-confirm/:uid/:token"/>
		</Router>;
	}
}

const mapStateToProps = (state: RootState) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(App);

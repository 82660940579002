import * as React from 'react';
import { connect } from 'react-redux';
import { List, Select, Skeleton, Switch } from 'antd';

import { State as iRootState } from '@/types';
import { Model as iModelConnection, State as iConnectionsState } from '@/state/models/connection';
import { update as updateConnection } from '@/state/reducers/connections';
import { getExpanded } from '@/state/selectors/connections';
import { t } from '@/utils';

interface group {
	key: string;
	title: string;
	info: any;
	description?: any;
}

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	connection: iModelConnection;
	connections: iConnectionsState;
	updateConnection: typeof updateConnection;
}

const ParentalControls: React.FunctionComponent<Props> = (props: Props) => {
	const groups: group[] = [{
		title: 'Age Restricted',
		key  : 'age_restrict',
		info : (record: iModelConnection) => t('connections.info_age_restricted'),
	}, {
		title: 'Min Age',
		key  : 'age_min',
		info : (record: iModelConnection) => t('connections.info_age_min'),
	}, {
		title: 'Safe search',
		key  : 'safe_search',
		info : (record: iModelConnection) => t('connections.info_safe_search'),
	}];

	const actionRenderer = (field: string, val: string | number | boolean, record: iModelConnection): React.ReactNode => {
		if (field == 'age_min') {
			return <Select
				value={parseInt(val.toString())}
				style={{ width: 70 }}
				disabled={!record.age_restrict}
				onChange={(val: number) => {
					props.updateConnection(Object.assign({}, record, {
						[field]: val,
					}));
				}}
			>
				{[3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((age) => {
					return <Select.Option key={age.toString()} value={age}>{age}</Select.Option>;
				})}
			</Select>;
		}
		return <Switch defaultChecked={record[field]} onChange={(val: boolean) => {
			const age_min = props.connection.age_min ? props.connection.age_min : 15;
			props.updateConnection(Object.assign({}, record, {
				[field]    : val,
				['age_min']: age_min,
			}));
		}}/>;
	};

	return (
		<List
			size={'small'}
			loading={props.connections.data.loading}
			itemLayout="horizontal"
			bordered={false}
			dataSource={[props.connection]}
			renderItem={(item: iModelConnection) => groups.map((group: any, i: number) => {
				return <List.Item
					key={group.key}
					actions={[actionRenderer(group.key, item[group.key], item)]}>
					<Skeleton title={false} loading={false} active>
						<List.Item.Meta
							title={group.title}
							description={group.info(item)}
						/>
					</Skeleton>
				</List.Item>;
			})}
		/>
	);
};


const mapDispatchToProps = { updateConnection };
const mapStateToProps = (state: iRootState) => ({
	connection : getExpanded(state),
	connections: state.connections,
});
export default connect(mapStateToProps, mapDispatchToProps)(ParentalControls);

import * as React from 'react';
import { connect } from 'react-redux';
import { IBrowser } from 'redux-responsive';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { Card, PageHeader, Button, Row, Col, Radio, Tooltip, Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import Layout from '../layout';
import SEO from '../seo';
import LogsList from '../logs/list';
import Search from '../logs/filter/search';
import { State as RootState } from '../../types';
import { iState as iStateProfile } from '../../state/models/profile';
import { update as updateUserProfile } from '../../state/reducers/profile';
import { t } from '@/utils';
import Devices from '../public/static/devices.svg';
import { iState as iModelState } from '../../state/models/logs';
import { navigate } from 'gatsby';
import { load, paginate } from '@/state/reducers/logs';
import { whitelist } from '@/state/reducers/rules';
import { State as iStateConnections } from '@/state/models/connection';
import LogsFilter from '../logs/filter';
import LogsPagination from '../logs/pagination';
import { RadioChangeEvent } from 'antd/lib/radio';


const LogsCard = styled(Card)`
	.ant-card-body {
		padding: 1px 0 0 0;
		margin-bottom: -2px;
	}

	.ant-empty {
		min-height: 140px;
		padding-top: 40px;
	}
`;

interface Props {
	logs: iModelState;
	browser: IBrowser;
	profile: iStateProfile;
	connections: iStateConnections;

	load: typeof load;
	whitelist: typeof whitelist;
	paginate: typeof paginate;
	updateUserProfile: typeof updateUserProfile;
}

export const LOGGING_LEVEL_DISABLED = 0;
export const LOGGING_LEVEL_BLOCKED_ONLY = 1;
export const LOGGING_LEVEL_ALL = 2;

export const FilterLogsPage: React.FunctionComponent<Props> = (props: Props) => {
	const setLogging = (level: number) => {
		props.updateUserProfile(Object.assign({}, props.profile.data, {
			logging_level: level,
		}));
	};

	React.useEffect(() => {
		if (!props.connections.data.results.length) {
			navigate('/app/connections');
		} else {
			props.load();
		}
	}, []);

	React.useEffect(() => {
		props.load();
	}, [props.profile.data.logging_level]);

	const renderNotEnabled = () => {
		return <>
			<Row gutter={0} style={{ textAlign: 'center' }}>
				<Col span={24}>
					<div style={{
						height    : 500,
						display   : 'block',
						width     : '100%',
						background: `transparent url(${Devices}) no-repeat center center`,
					}}
					>&nbsp;</div>
					<h2 style={{
						margin    : '40px auto 0 auto',
						fontWeight: 800,
						fontSize  : '3rem',
						display   : 'block',
					}}>
						{t('logs.title_disabled')}
					</h2>
				</Col>

				<Col span={24}>
					<p style={{
						display: 'block',
						margin : props.browser.lessThan.medium ? '0 10px' : '0 160px',
					}}>{t('logs.info_disabled')}</p>
				</Col>

				<Col span={24}>
					<Button block size={'large'}
						  type="primary"
						  style={{
							  maxWidth  : 500,
							  margin    : '40px auto 0 auto',
							  height    : 80,
							  fontWeight: 800,
							  fontSize  : '2rem',
						  }}
						  onClick={() => setLogging(LOGGING_LEVEL_BLOCKED_ONLY)}
					>
						<b>{t('logs.enable')}</b>
					</Button>
				</Col>
			</Row>
		</>;
	};

	const actions = () => {
		return props.browser.greaterThan.small ? [
			<Radio.Group
				key={'logs_status'}
				value={props.profile.data.logging_level}
				onChange={(e: RadioChangeEvent) => setLogging(e.target.value)}
				buttonStyle="solid"
			>
				<Tooltip placement="topRight" title={t('logs.status_disabled')} trigger="hover">
					<Radio.Button value={LOGGING_LEVEL_DISABLED}>{t('misc.disabled')}</Radio.Button>
				</Tooltip>
				<Tooltip placement="topRight" title={t('logs.status_blocked')} trigger="hover">
					<Radio.Button value={LOGGING_LEVEL_BLOCKED_ONLY}>{t('misc.blocked')}</Radio.Button>
				</Tooltip>
				<Tooltip placement="topRight" title={t('logs.status_all')} trigger="hover">
					<Radio.Button value={LOGGING_LEVEL_ALL}>{t('misc.all')}</Radio.Button>
				</Tooltip>
			</Radio.Group>,
		] : [];
	};

	const { paginate, profile } = props;
	const { logging_level } = profile.data;
	const { loaded, loading } = props.logs.data;
	const firstLoad = loading && !loaded;
	const notEnabled = profile.loaded && !logging_level;

	const renderer = () => {
		if (notEnabled) {
			return renderNotEnabled();
		}

		if (firstLoad) {
			return (
				<div style={{ marginTop: 20 }}>
					<Spin/>
				</div>
			);
		}

		return (
			<div style={{ marginTop: 20 }}>
				<LogsCard>
					<PageHeader
						ghost={false}
						title={t('logs.secondary_title')}
						subTitle={t('logs.subtitle')}
						style={{
							padding: '20px 20px 0 20px',
						}}
						footer={<div style={{
							marginBottom: 15,
						}}>
							<Search key={'search-form'} style={{ width: '100%' }}/>
						</div>}
						extra={actions()}
					/>

					<Row>
						<Col lg={18} xs={24} style={{
							padding  : '10px 20px 20px 20px',
							minHeight: isMobile ? 'inherit' : 800,
						}}>
							<LogsList/>
						</Col>
						<Col lg={6} xs={0} style={{
							padding : '10px 20px 0 10px',
							position: 'relative',
						}}>
							<LogsFilter/>
							<div style={{
								position: 'absolute',
								bottom  : 20,
								right   : 20,
							}}>
								<Tooltip title="reload">
									<Button
										disabled={loading}
										shape="circle"
										icon={<ReloadOutlined spin={loading}/>}
										onClick={() => paginate()}
									/>
								</Tooltip>
							</div>
						</Col>
					</Row>
				</LogsCard>

				<div style={{ textAlign: 'right', marginTop: 20 }}>
					<LogsPagination/>
				</div>
			</div>
		);
	};

	return <Layout titleProps={{
		title: t('logs.title'),
	}}>
		<SEO title={t('logs.title')}/>

		{renderer()}
	</Layout>;
};


const mapStateToProps = (state: RootState) => {
	return {
		logs       : state.logs,
		browser    : state.browser,
		profile    : state.profile,
		connections: state.connections,
	};
};

const mapDispatchToProps = { updateUserProfile, load, paginate, whitelist };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(FilterLogsPage);

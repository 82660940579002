import * as React from 'react';

import { Tag } from 'antd';
import { iModel } from '../state/models/rule_categories';


interface Props {
	category: iModel;
	text?: boolean;
}

interface State {

}

export default class RuleType extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	get label() {
		const { category, text } = this.props;
		if (text) {
			return category ? <b style={{
				color: category.color,
			}}>{category.name}</b> : null;
		}
		return category ? <Tag color={category.color}>{category.name}</Tag> : null;
	}

	render() {
		return this.label;
	}
}

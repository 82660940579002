import * as React from 'react';
import { connect } from 'react-redux';
import { IBrowser } from 'redux-responsive';

import styled from 'styled-components';
import { BulbOutlined } from '@ant-design/icons/lib';
import ClickToSelect from '@mapbox/react-click-to-select';

import { State as RootState, PaginationPayload } from '../../types';
import { DeleteOutlined } from '@ant-design/icons';
import {
	Button,
	Card,
	Table,
	Popconfirm,
	Popover,
	message,
	Row,
	Col,
} from 'antd';

import {
	load,
	paginate,
	formModalShow,
	update,
	deleteConnection,
	setExpanded,
} from '@/state/reducers/connections';
import {
	State as iStateConnections,
	Model as iModelConnection,
	connectionsPerPage, Model,
} from '@/state/models/connection';
import { t } from '@/utils';
import { PaginationFilter, PaginationSorter } from '@/types';
import { iState as iStateProfile } from '@/state/models/profile';
import ConnectionDrawer from '@/components/connections/connection/drawer';
import Misc from '@/components/connections/connection/misc';
import GeneralInfo from '@/components/connections/connection/general_info';
import BlockGroups from '@/components/connections/connection/block_groups';
import MobilePhones from '@/components/connections/connection/mobile_phones';
import ParentalControls from '@/components/connections/connection/parental_controls';
import SetupInstructions from '@/components/connections/connection/setup_instructions';
import AuthenticationInstructions from '@/components/connections/connection/authentication_instructions';
import ReRoute from '@/components/connections/connection/route_target';

const Wrapper = styled.div`
	.grid-block {
		cursor: pointer;
		text-align: center;


		.ant-card-meta-title {
			font-weight: 600;
			font-size: 1.4rem;
		}
	}
`;

interface TileTitleProps {
	isDirty?: boolean;
}

const TileTitle = styled.span<TileTitleProps>`
	color: ${(props: TileTitleProps) => props.isDirty ? 'var(--color-primary)' : 'inherit'};
	font-style: ${(props: TileTitleProps) => props.isDirty ? 'italic' : 'inherit'};
`;

const ConnectionsCard = styled(Card)`
	.ant-card-body {
		padding: 1px 0 0 0;
		margin-bottom: -2px;
	}

	.ant-empty {
		min-height: 140px;
		padding-top: 40px;
	}

	tr {
		td {
			&:first-child {
				padding-left: 20px !important;
			}

			&:last-child {
				padding-right: 20px !important;
			}
		}
	}
`;

interface Props {
	browser: IBrowser;
	load: () => void;
	paginate: (params: PaginationPayload) => void;
	profile: iStateProfile;
	connections: iStateConnections;
	update: typeof update;
	deleteConnection: (record: iModelConnection) => void;
	formModalShow: () => void;
	setExpanded: (id: number | undefined) => void;
}

interface State {
	modalVisible: boolean;
	// state drawer for conection various info
	connectionInfoDrawerOpen: boolean;
	connectionInfoDrawerComponent: string;
}

export class Connections extends React.Component<Props & React.ReactNode, State> {
	state = {
		modalVisible                 : false,
		connectionInfoDrawerOpen     : false,
		connectionInfoDrawerComponent: 'server_info',
	};

	componentDidMount(): void {
		this.props.load();
	}

	//	will set keys for rows so we don't get errors rendering
	get preparedData(): any {
		const data = [];
		for (let i = 0; i < this.props.connections.data.results.length; i++) {
			const item = Object.assign(this.props.connections.data.results[i], {
				key: `connection-${this.props.connections.data.results[i].id}`,
			});

			data.push(item);
		}

		return data;
	}

	handleTableChange(pagination: any, filters: any, sorter: any) {
		const pageNumber = pagination.current;

		const pFilters: PaginationFilter[] = [];
		const pSorters: PaginationSorter[] = [];

		if (sorter && sorter.order) {
			pSorters.push({
				column: sorter.field,
				order : sorter.order,
			});
		}

		this.props.paginate({
			pageNumber, filters: pFilters, sorters: pSorters,
		});
	}

	render() {
		const { profile, connections, browser } = this.props;

		const expandedRowRender = (record: iModelConnection) => {
			const showConnDrawer = (component: string) => {
				this.setState({ connectionInfoDrawerOpen: true, connectionInfoDrawerComponent: component });
			};

			const drawerTitle = (): string => {
				switch (this.state.connectionInfoDrawerComponent) {
					case 'general_info':
						return 'General info';
					case 'block_gropus':
						return 'Block groups';
					case 'parental':
						return 'Parental controls';
					case 'authentication':
						return 'Authentication';
					case 'mobile':
						return 'Mobile connection';
					case 'misc':
						return 'Misc - other settings';
					case 'setup_instructions':
						return 'Setup instructions';
					case 'schedule':
						return 'Re-route schedule';
				}
				return '';
			};

			const drawerContent = (): React.ReactNode => {
				switch (this.state.connectionInfoDrawerComponent) {
					case 'general_info':
						return <div>
							<GeneralInfo style={{ marginTop: -1, marginBottom: 20 }}/>
							<div style={{ padding: 0 }}>
								<Card
									style={{ marginTop: 16, border: 'none', padding: 0 }}
								>
									<Card.Meta
										avatar={<BulbOutlined/>}
										title={t('connections.dot_info_title')}
										description={t('connections.dot_info')}
									/>
								</Card>
								<Card
									style={{ marginTop: 16, border: 'none', padding: 0 }}
								>
									<Card.Meta
										avatar={<BulbOutlined/>}
										title={t('connections.doh_info_title')}
										description={t('connections.doh_info')}
									/>
								</Card>
							</div>
						</div>;
					case 'block_gropus':
						return <div>
							<BlockGroups/>
							<div style={{ padding: 0 }}>
								<Card
									style={{ marginTop: 16, border: 'none', padding: 0 }}
								>
									<Card.Meta
										avatar={<BulbOutlined/>}
										title={t('connections.block_groups_title')}
										description={t('connections.block_groups_info')}
									/>
								</Card>
							</div>
						</div>;
					case 'parental':
						return <ParentalControls/>;
					case 'authentication':
						return <AuthenticationInstructions/>;
					case 'mobile':
						return <MobilePhones/>;
					case 'misc':
						return <Misc/>;
					case 'setup_instructions':
						return <div style={{ padding: 20 }}>
							<SetupInstructions/>
						</div>;
					case 'schedule':
						return <div style={{ padding: 20 }}>
							<ReRoute/>
						</div>;
				}
				return '';
			};

			const gridStyle = {
				width: browser.lessThan.large ? (browser.lessThan.medium ? '100%' : '50%') : '33.33333%',
			};

			// dirty states to colorize drawer tiles
			let rerouteDirty: boolean = (record.route_target?.target_id && record.route_target?.target_id > 0) || false;
			let parentalDirty: boolean = record.safe_search || record.age_restrict;
			let authDirty: boolean = record.ip_address != '' || record.dynamicdns_hostname != '';
			let groupsDirty: boolean = record.rules_categories.length > 0;
			let miscDirty: boolean = record.lockdown_mode;


			return <div>
				<ConnectionDrawer
					title={drawerTitle()}
					open={this.state.connectionInfoDrawerOpen}
					closeAction={() => this.setState({ connectionInfoDrawerOpen: false })}
				>
					{drawerContent()}
				</ConnectionDrawer>

				<Card style={{ margin: '20px 0' }}>
					<Card.Grid style={gridStyle} className={'grid-block'}>
						<div onClick={(e: any) => showConnDrawer('setup_instructions')}>
							<Card.Meta title={<span>Setup instructions</span>}/>
						</div>
					</Card.Grid>
					<Card.Grid style={gridStyle} className={'grid-block'}>
						<div onClick={(e: any) => showConnDrawer('general_info')}>
							<Card.Meta title={<span>General info</span>}/>
						</div>
					</Card.Grid>
					<Card.Grid style={gridStyle} className={'grid-block'}>
						<div onClick={(e: any) => showConnDrawer('block_gropus')}>
							<Card.Meta title={
								<TileTitle isDirty={groupsDirty}>Block groups</TileTitle>
							}/>
						</div>
					</Card.Grid>
					<Card.Grid style={gridStyle} className={'grid-block'}>
						<div onClick={(e: any) => showConnDrawer('parental')}>
							<Card.Meta title={
								<TileTitle isDirty={parentalDirty}>Parental controls</TileTitle>}
							/>
						</div>
					</Card.Grid>
					<Card.Grid style={gridStyle} className={'grid-block'}>
						<div onClick={(e: any) => showConnDrawer('authentication')}>
							<Card.Meta title={
								<TileTitle isDirty={authDirty}>Authentication</TileTitle>}
							/>
						</div>
					</Card.Grid>
					<Card.Grid style={gridStyle} className={'grid-block'}>
						<div onClick={(e: any) => showConnDrawer('mobile')}>
							<Card.Meta title={<span>Mobile connection</span>}/>
						</div>
					</Card.Grid>
					<Card.Grid style={gridStyle} className={'grid-block'}>
						<div onClick={(e: any) => showConnDrawer('schedule')}>
							<Card.Meta title={
								<TileTitle isDirty={rerouteDirty}>Routing schedule</TileTitle>}
							/>
						</div>
					</Card.Grid>
					<Card.Grid style={gridStyle} className={'grid-block'}>
						<div onClick={(e: any) => showConnDrawer('misc')}>
							<Card.Meta title={
								<TileTitle isDirty={miscDirty}>Misc - other settings</TileTitle>}
							/>
						</div>
					</Card.Grid>
				</Card>
			</div>;
		};

		const columns = [{
			title    : 'Alias',
			dataIndex: 'alias',
			key      : 'alias',
		}, {
			title    : 'DNS server (IPv4)',
			dataIndex: 'dns_server_v4',
			key      : 'server.ipv4',
			width    : 150,
			align    : 'right' as 'right',
			render   : (val: any, record: iModelConnection) => {
				return <ClickToSelect onSelect={() => {
					if (document.queryCommandSupported('copy')) {
						document.execCommand('copy');
						message.info(t('connections.server_copied'));
					}
				}}>
					<b style={{ borderBottom: '1px dashed #333', marginRight: 8 }}>
						{record?.server?.ipv4}
					</b>
				</ClickToSelect>;
			},
		}, {
			title    : 'DNS server (IPv6)',
			dataIndex: 'dns_server_v6',
			key      : 'server.ipv6',
			width    : 240,
			align    : 'right' as 'right',
			render   : (val: any, record: iModelConnection) => {
				return <ClickToSelect onSelect={() => {
					if (document.queryCommandSupported('copy')) {
						document.execCommand('copy');
						message.info(t('connections.server_copied'));
					}
				}}>
					<b style={{ borderBottom: '1px dashed #333', marginRight: 8 }}>
						{record?.server?.ipv6}
					</b>
				</ClickToSelect>;
			},
		}, {
			title    : 'Config ID',
			dataIndex: 'identifier',
			key      : 'identifier',
			width    : 120,
			align    : 'right' as 'right',
			render   : (val: any, record: iModelConnection) => {
				return <Popover
					title={
						<b>Your config ID</b>}
					content={t('connections.info_config_id')} placement="top" arrowPointAtCenter
					overlayStyle={{ maxWidth: 300 }}>
					<span style={{ borderBottom: '1px dashed #333', marginRight: 8 }}>
						<ClickToSelect onSelect={() => {
							if (document.queryCommandSupported('copy')) {
								document.execCommand('copy');
								message.info(t('connections.config_id_copied'));
							}
						}}>{record?.identifier}</ClickToSelect>
					</span>
				</Popover>;
			},
		}, {
			title    : ' ',
			dataIndex: 'options',
			key      : 'options',
			align    : 'right' as 'right',
			width    : 60,
			render   : (val: string, record: iModelConnection) => {
				return (
					<Popconfirm
						title="Delete record?"
						placement={'left'}
						onConfirm={() => {
							this.props.deleteConnection(record);
						}}
					>
						<Button danger icon={<DeleteOutlined/>} size={'small'} ghost={true}/>
					</Popconfirm>
				);
			},
		}];

		if (browser.lessThan.medium) {
			columns.splice(2, 1);
		}

		return (
			<Wrapper>
				<ConnectionsCard
					title={<div>
						<Row>
							<Col span={16} className={'ellipsis'}>
								<b style={{
									verticalAlign: 'middle',
									marginTop    : 5,
									display      : 'inline-block',
								}}>
									{browser.lessThan.small ? 'Configurations' : t(
										'connections.allowed_info',
									).format(
										connections.data.count,
										profile.data.connections_limit,
									)}
								</b>
							</Col>

							<Col span={8}>
								<div style={{ float: 'right' }}>
									<Button
										type={'primary'}
										onClick={() => this.props.formModalShow()}
									>Create connection</Button>
								</div>
							</Col>
						</Row>
					</div>}
					style={{ padding: 0, paddingBottom: 2 }}
				>
					<Table
						size={'middle'}
						pagination={{
							hideOnSinglePage: false,
							total           : connections.data.count,
							showTotal       : (total: number, range: number[]) => {
								return <div>
									<b>{range[0]}-{range[1]}</b> of <b>{Number(connections.data.count).toLocaleString()}</b> items
								</div>;
							},
							current         : connections.data.pageNumber,
							pageSize        : connectionsPerPage,
							defaultPageSize : connectionsPerPage,
							style           : {
								marginRight: 12,
							},
						}}
						loading={connections.data.loading}
						expandedRowRender={expandedRowRender}
						expandedRowKeys={connections.expandedConnection ? [`connection-${connections.expandedConnection}`] : []}
						columns={columns}
						onExpand={(expanded: boolean, record: Model) => {
							this.props.setExpanded(expanded ? record.id : undefined);
						}}
						dataSource={this.preparedData}
						onChange={this.handleTableChange.bind(this)}
						bordered={false}
					/>

				</ConnectionsCard>
			</Wrapper>
		);
	}
}

const mapStateToProps = (state: RootState) => {
	return {
		profile    : state.profile,
		connections: state.connections,
		browser    : state.browser,
	};
};

const mapDispatchToProps = { paginate, load, update, deleteConnection, setExpanded, formModalShow };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Connections);

import React from 'react';

import { Row, Col } from 'antd';

import DownloadCard from './download-card';
import { GuiWarn } from '@/components/pages/download/index';

interface Props {
	version: string;
}

export default ({ version }: Props) => {
	return (
		<Row gutter={20}>
			<Col xs={24} md={24} lg={8} style={{ marginTop: 20, paddingTop: 20 }}>
				<b>Desktop app (GUI)</b>
				<GuiWarn/>
			</Col>
			<Col xs={24} md={24} lg={16}>
				<DownloadCard
					title={'dnsadblock.exe'}
					description={'Installer for our desktop app (GUI)'}
					downloadUrl={`https://d3tzov1vemmztr.cloudfront.net/dnsadblock-desktop-${version}.exe`}
				/>
			</Col>
		</Row>
	);
};

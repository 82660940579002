import * as React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';

import { t } from '@/utils';
import { State as iRootState } from '@/types';
import { formModalHide, formReset } from '@/state/reducers/connections';

import ConnectionsForm from './form';

interface Props {
	visible: boolean;
	formModalHide: () => void;
}

const ConnectionsModal: React.FunctionComponent<Props> = (props: Props) => {
	const [reset, setReset] = React.useState(false);
	const [submit, setSubmit] = React.useState(false);

	const { formModalHide } = props;
	return (
		<Modal
			visible={props.visible}
			title={<b>{t('connections.create_modal')}</b>}
			okText={'Create'}
			onCancel={() => {
				setReset(true);
				formModalHide();
			}}
			onOk={() => {
				setSubmit(true);
				setTimeout(() => setSubmit(false), 200);
			}}
			destroyOnClose
		>
			<ConnectionsForm signals={{ reset, submit }}/>
		</Modal>
	);
};


const mapDispatchToProps = { formReset, formModalHide };
const mapStateToProps = (state: iRootState) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ConnectionsModal);

import * as React from 'react';
import { connect } from 'react-redux';

import {
	AppleOutlined,
	CodeOutlined,
	DeploymentUnitOutlined,
	MobileOutlined,
	WindowsOutlined,
} from '@ant-design/icons';

import { Tabs, Alert } from 'antd';

import Layout from '../../layout';
import SEO from '../../seo';
import { State as iRootState } from '@/types';
import { formModalShow } from '@/state/reducers/connections';

import MacOsInstructions from './macos';
import LinuxInstructions from './linux';
import MobileInstructions from './mobile';
import RoutersInstructions from './routers';
import WindowsInstructions from './windows';
import styled from 'styled-components';

const { TabPane } = Tabs;

const StyledAlert = styled(Alert)`
	margin-bottom: 20px;
	p {
		margin: 0;
	} 
	
	a {
		font-weight: bold;
	}
`;

interface Props {
	key?: string;
}

interface State {

}

const guiVersion = '0.0.81';


interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {

}

export const GuiWarn: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<p>
			Recommended for single computer use. <b>Will not cover other members
			of your network.</b>
		</p>
	);
};

export class DownloadPage extends React.Component<Props & React.ReactNode, State> {
	render() {
		return (
			<Layout titleProps={{
				title: 'How to install and use our software',
			}}>
				<SEO title="How to install DnsAdBlock software"/>

				<StyledAlert
					message={<b>There are other options</b>}
					description={<p>
						The following app presented here is just one way of running <b>DnsAdBlock</b>.
						We have other options including guides on setting up your routers, using along
						with a VPN for better privacy, authenticating with dynamic IPs etc. Please
						head over to our <a
						target={'__blank'}
						href={'https://knowledgebase.dnsadblock.com/how-to-install-and-configure-our-software/'}
					>Knowledgebase</a> for info.
					</p>}
					type="info"
				/>

				<Tabs defaultActiveKey="1">
					<TabPane tab={<b><WindowsOutlined/> Windows</b>} key="1">
						<WindowsInstructions version={guiVersion}/>
					</TabPane>
					<TabPane tab={<b><CodeOutlined/> Linux</b>} key="2">
						<LinuxInstructions version={guiVersion}/>
					</TabPane>
					<TabPane tab={<b><DeploymentUnitOutlined/> Routers</b>} key="3">
						<RoutersInstructions version={guiVersion}/>
					</TabPane>
					<TabPane tab={<b><AppleOutlined/> MacOS</b>} key="4">
						<MacOsInstructions version={guiVersion}/>
					</TabPane>
					<TabPane tab={<b><MobileOutlined/> Mobile &amp; Tablets</b>} key="5">
						<MobileInstructions version={guiVersion}/>
					</TabPane>
				</Tabs>
			</Layout>
		);
	}
}

const mapStateToProps = (state: iRootState) => {
	return {};
};

const mapDispatchToProps = { formModalShow };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DownloadPage);


import * as React from 'react';
import { connect } from 'react-redux';

import { Card, Radio, Col, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import { State as iRootState } from '../../../types';
import {
	load as loadStats,
	setInterval,
	loadForConnection as loadConnectionStats,
	iState as iStateConnectionStats,
} from '../../../state/reducers/connection_stats';

import BarChart, { DonutChart } from './connections-charts';


interface Props {
	title: string;
	loadStats: () => void;
	setInterval: (i: string) => void;
	loadConnectionStats: (id: number) => void;

	connection_stats: iStateConnectionStats;
}

interface State {

}

export class ConnectionCharts extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	componentDidMount(): void {
		const { connection_stats, loadStats, loadConnectionStats } = this.props;
		if (connection_stats.connectionId) {
			loadConnectionStats(connection_stats.connectionId);
		} else {
			loadStats();
		}
	}

	handleSizeChange = (e: RadioChangeEvent) => {
		this.props.setInterval(e.target.value);
	}

	render() {
		const { title, connection_stats } = this.props;

		const aBlocked = connection_stats.results.map((r) => r.blocked);
		const aAllowed = connection_stats.results.map((r) => r.allowed);
		const aTimestamps = connection_stats.results.map((r) => new Date(`${r.timestamp}`).toString());

		return <Card title={title} extra={
			<Radio.Group buttonStyle="solid" value={connection_stats.interval} onChange={this.handleSizeChange}>
				<Radio.Button value="1-M">30d</Radio.Button>
				<Radio.Button value="1-W">7d</Radio.Button>
				<Radio.Button value="1-D">24h</Radio.Button>
				<Radio.Button value="1-h">1h</Radio.Button>
			</Radio.Group>
		} loading={connection_stats.loading}>
			<Row gutter={0}>
				<Col xs={24} md={24} lg={16}>
					<BarChart
						blocked={aBlocked}
						allowed={aAllowed}
						timestamps={aTimestamps}
					/>
				</Col>

				<Col
					xs={24} md={24} lg={8}
					style={{ alignSelf: 'center', position: 'relative' }}
				>
					<DonutChart
						blocked={aBlocked}
						allowed={aAllowed}
						timestamps={aTimestamps}
					/>
				</Col>
			</Row>
		</Card>;
	}
}


const mapStateToProps = (state: iRootState) => {
	return {
		connection_stats: state.connection_stats,
	};
};

const mapDispatchToProps = { setInterval, loadStats, loadConnectionStats };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ConnectionCharts);


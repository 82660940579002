import * as React from 'react';
//	https://github.com/apexcharts/react-apexcharts/issues/44
// @ts-ignore
import Loadable from '@loadable/component';

const LoadableChart = Loadable(() => import(
// @ts-ignore
	'../../../../node_modules/react-apexcharts/src/react-apexcharts'));

interface Props {
	blocked: number[];
	allowed: number[];
	timestamps: string[];
}

interface State {

}

// Referenced also in device stats - do not delete !
export default class BarChart extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		const { allowed, blocked, timestamps } = this.props;

		const options = {
			series: [{
				name: 'Blocked',
				data: blocked,
			}, {
				name: 'Allowed',
				data: allowed,
			}],
			config: {
				chart      : {
					type   : 'bar',
					zoom   : {
						enabled: false,
					},
					toolbar: {
						show: false,
					},
					stacked: true,
				},
				dataLabels : {
					enabled: false,
				},
				colors     : ['#E91E63', '#2F57E4'],
				plotOptions: {
					bar: {
						horizontal : false,
						columnWidth: '100%',
					},
				},
				stroke     : {
					show  : true,
					width : 2,
					colors: ['transparent'],
				},
				tooltip    : {
					x: {
						show  : false,
						format: 'dd MMM HH:mm',
					},
				},
				xaxis      : {
					type      : 'datetime',
					categories: timestamps.map(
						(ts) => {
							const formatter = new Intl.DateTimeFormat(['en-US'], {
								timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
								year    : 'numeric',
								month   : 'numeric',
								day     : 'numeric',
								hour    : 'numeric',
								minute  : 'numeric',
								second  : 'numeric',
							});
							return formatter.format(new Date(ts));
						},
					),
					labels    : {
						datetimeFormatter: {
							year  : 'yyyy',
							month : 'MMM \'yy',
							day   : 'dd MMM',
							hour  : 'HH TT',
							minute: 'HH:mm TT',
						},

					},
				},
				fill       : {
					opacity: 1,
				},
				noData     : {
					text: 'No data for selected interval',
				},
			},
		};

		return <LoadableChart height={300} options={options.config} series={options.series} type={'bar'}/>;
	}
}

export class DonutChart extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		const { allowed, blocked } = this.props;

		const options = {
			series: [
				blocked.reduce((a, b) => a + b, 0),
				allowed.reduce((a, b) => a + b, 0),
			],
			chart : {
				type: 'donut',
			},
			legend: {
				position : 'bottom',
				formatter: (val: number, opts: any) => {
					return val + ' - ' + Number(opts.w.globals.series[opts.seriesIndex]).toLocaleString();
				},
			},
			labels: ['Blocked', 'Allowed'],
			colors: ['#E91E63', '#2F57E4'],
		};

		return <LoadableChart
			height={300}
			options={options}
			series={options.series}
			type="donut"
		/>;
	}
}


import * as React from 'react';
import { connect } from 'react-redux';
import { Pagination } from 'antd';
import { IBrowser } from 'redux-responsive';

import { State as iRootState } from '@/types';
import { iState } from '@/state/models/device';
import { paginate } from '@/state/reducers/devices';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	devices: iState;
	browser: IBrowser;
	paginate: typeof paginate;
}

const DevicesPagination: React.FunctionComponent<Props> = (props: Props) => {
	const onChange = (page: number) => {
		props.paginate({
			pageNumber: page,
		});
	};

	return (
		<Pagination
			onChange={onChange}
			defaultCurrent={1}
			pageSize={props.devices.data.pageSize}
			current={props.devices.data.pageNumber}
			total={props.devices.data.count}
		/>
	);
};


const mapDispatchToProps = { paginate };
const mapStateToProps = (state: iRootState) => ({
	devices: state.devices,
	browser: state.browser,
});
export default connect(mapStateToProps, mapDispatchToProps)(DevicesPagination);

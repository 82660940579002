import * as React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Card, Skeleton, Tooltip } from 'antd';


interface iDownloadCard {
	title: string;
	description: string;
	downloadUrl: string;
	disabled?: boolean;
	inNewTab?: boolean;
	icon?: React.ReactNode;
}

export default class DownloadCard extends React.Component<iDownloadCard & React.ReactNode, {}> {
	render() {
		const { Meta } = Card;
		const { title, description, downloadUrl, disabled, inNewTab, icon, ...rest } = this.props;

		const download = () => {
			if (!disabled) {
				if (inNewTab) {
					// @ts-ignore
					return window.open(downloadUrl, '_blank').focus();
				}
				window.location.href = downloadUrl;
			}
		};

		return (
			<Tooltip title={disabled ? 'Coming soon' : undefined}>
				<Card
					hoverable={!disabled}
					onClick={download}
					style={{ margin: "20px 10px 20px 0" }}
				>
					<Skeleton loading={false} avatar active>
						<Meta
							avatar={icon || <DownloadOutlined key="download"/>}
							title={<b>{title}</b>}
							description={description}
						/>
					</Skeleton>
				</Card>
			</Tooltip>
		);
	}
}

import React from 'react';

interface Props {

}

const guideUrl = 'https://knowledgebase.dnsadblock.com/how-to-install-and-configure-our-software';
export default ({}: Props) => {
	return (
		<div>
			<h2 style={{ fontWeight: 'bolder' }}>Unix client</h2>

			<p>
				For this distro/os we recommend installing and using our unix client which can be configured to
				run as a daemon or as a system service and can configure itself for a router setup. The multiple
				configurations it supports as well as installation guides fall outside the scope of
				this page so we invite you to read more on our <a href={guideUrl}>
				<b>Knowledgebase</b>
			</a> instead.
			</p>
		</div>
	);
};

import * as React from 'react';
import { Alert, Layout as ALayout } from 'antd';

import Layout from '../layout';
import SEO from '../seo';
import Rules from '../rules';

interface Props {

}

interface State {

}

export default class FiltersPage extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return <Layout titleProps={{
			title: 'Dns filters',
		}}>
			<SEO title="Filters"/>

			<ALayout>
				<Alert
					message={<b>Customize your experience</b>}
					description={<div>
						Use this page to create your custom filters/rules for blocking or allowing
						traffic from certain domains. The rules you define here will be applied to
						all connections that you created (they are global). <b>If a rule that you add
						is also present in our list, your rule will take precedence when we serve
						your DNS queries.</b>
					</div>} type="info"/>
			</ALayout>

			<Rules style={{ marginTop: 20 }} tableProps={{
				size    : 'small',
				bordered: false,
			}}/>
		</Layout>;
	}
}

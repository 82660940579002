import * as React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { IBrowser } from 'redux-responsive';

import { Drawer, Row, Col, Spin } from 'antd';

import { State as iRootState } from '@/types';
import { iState } from '@/state/models/device';
import { toggleStatsDrawer } from '@/state/reducers/devices';
import Timeline from './charts/timeline';
import Device from '@/components/devices/device';
import AllTimeStats from '@/components/devices/stats/all-time';
import AllTimeBlockedStats from '@/components/devices/stats/blocked';
import DeviceConnectionMapForm from '@/components/devices/connection_map_form';

const StyledDrawer = styled(Drawer)`
	.card-stats {
		padding: 20px;
		background: #f0f2f5;
		margin-top: 1px!important;
		border-top: 1px solid var(--color-border)!important;
		border-bottom: 1px solid var(--color-border);
	}
	
	.ant-drawer-header {
		padding: 16px 15px;
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	devices: iState;
	browser: IBrowser;

	toggleStatsDrawer: typeof toggleStatsDrawer;
}

const LogsDrawer: React.FunctionComponent<Props> = (props: Props) => {
	const { browser, devices, toggleStatsDrawer } = props;

	const width = () => {
		let w = '100%';
		if (browser.greaterThan.small) {
			w = '90%';
		}
		if (browser.greaterThan.medium) {
			w = '800';
		}
		return w;
	};

	return (
		<StyledDrawer
			title={<b>{devices.selected?.device_name}</b>}
			placement="right"
			closable={true}
			destroyOnClose={true}
			visible={devices.statsDrawerOpen}
			width={width()}
			onClose={() => toggleStatsDrawer(false)}
			bodyStyle={{ padding: 0, overflowX: 'hidden' }}
		>
			<Spin spinning={devices.data.loading}>
				<DeviceConnectionMapForm/>
				<Device style={{ marginTop: -1, marginBottom: 20 }}/>
				<Timeline title={''}/>
				<Row gutter={[16, 16]} className={'card-stats'}>
					<Col md={12} xs={24}>
						<AllTimeStats/>
					</Col>
					<Col md={12} xs={24}>
						<AllTimeBlockedStats/>
					</Col>
				</Row>
			</Spin>
		</StyledDrawer>
	);
};


const mapDispatchToProps = { toggleStatsDrawer };
const mapStateToProps = (state: iRootState) => ({
	devices: state.devices,
	browser: state.browser,
});
export default connect(mapStateToProps, mapDispatchToProps)(LogsDrawer);

import * as React from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';

import { Form, Input, Button, message } from 'antd';

import { formFieldError, t } from '@/utils';
import { State as RootState } from '@/types';
import { createNewPasswordUrl } from '@/routes';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	token?: string;
	uid?: string;
}

const UpdatePasswordForm: React.FunctionComponent<Props> = (props) => {
	const { uid, token, ...rest } = props;
	const [errors, setErrors] = React.useState({});
	const [form] = Form.useForm();

	const handleSumbit = (e: any) => {
		e.preventDefault();
		const new_password1 = form.getFieldValue('new_password1');
		const new_password2 = form.getFieldValue('new_password2');
		setErrors({});

		axios.post(
			`${createNewPasswordUrl}${uid}/${token}/`,
			{ uid, token, new_password1, new_password2 },
		).then((response) => {
			message.success(response.data.detail);
			navigate('/app/login');
		}).catch(console.error);
	};

	const reqR = () => Object.assign({}, { required: true, message: t('misc.form.validation_required') });

	return <div {...rest}>
		<Form form={form} layout="vertical" style={{ width: '100%' }}>
			<Form.Item {...formFieldError('new_password1', errors)}>
				<Form.Item name="new_password1" noStyle rules={[
					reqR(), ({ getFieldValue }) => ({
						validator(rule, value) {
							if (!value) return Promise.resolve();
						},
					}),
				]}>
					<Input type={'password'} placeholder={'New password'} size={'large'}/>
				</Form.Item>
			</Form.Item>
			<Form.Item {...formFieldError('new_password2', errors)}>
				<Form.Item
					noStyle
					name="new_password2"
					dependencies={['new_password1']}
					rules={[reqR()]}
				>
					<Input type={'password'} placeholder={'Confirm password'} size={'large'}/>
				</Form.Item>
			</Form.Item>

			<Form.Item>
				<Button
					htmlType="submit"
					type={'primary'}
					block={true}
					size={'large'}
					onClick={handleSumbit}
					style={{ marginTop: 10 }}
				>Set password</Button>
			</Form.Item>
		</Form>
	</div>;
};

const mapStateToProps = (state: RootState) => {
	return {};
};

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(UpdatePasswordForm);

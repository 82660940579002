import React from 'react';

import { Col, Row } from 'antd';

import DownloadCard from '@/components/pages/download/download-card';
import { GuiWarn } from '@/components/pages/download/index';
import UnixClientGuide from '@/components/pages/download/unix-client-guide';

interface Props {
	version: string;
}

export default ({ version }: Props) => {
	return (
		<div style={{ marginTop: 40 }}>
			<div style={{ marginBottom: 60 }}>
				<UnixClientGuide/>
			</div>

			<h2 style={{ fontWeight: 'bolder' }}>Desktop app (GUI)</h2>
			<GuiWarn/>

			<Row gutter={20}>
				<Col xs={24} md={24} lg={24}>
					<DownloadCard
						title={'dnsadblock.dmg'}
						description={'Installer for our desktop app (GUI)'}
						downloadUrl={`https://d3tzov1vemmztr.cloudfront.net/dnsadblock-desktop-${version}.dmg`}
					/>
				</Col>
			</Row>
		</div>
	);
};

import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { IBrowser } from 'redux-responsive';

import { Drawer } from 'antd';

import { State as iRootState } from '@/types';

const Wrapper = styled(Drawer)`
	.ant-drawer-header {
		padding: 16px 15px;
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	title: string;
	open: boolean;
	closeAction: () => void;
	browser: IBrowser;
	children: React.ReactNode;
}

const ConnectionDrawer: React.FunctionComponent<Props> = (props: Props) => {
	const { title, open, closeAction, browser, children } = props;

	const width = () => {
		let w = '100%';
		if (browser.greaterThan.small) {
			w = '90%';
		}
		if (browser.greaterThan.medium) {
			w = '800';
		}
		return w;
	};

	return (
		<Wrapper
			title={<b>{title}</b>}
			placement="right"
			closable={true}
			destroyOnClose={true}
			visible={open}
			width={width()}
			onClose={() => closeAction()}
			bodyStyle={{ padding: 0, overflowX: 'hidden' }}
		>
			{children}
		</Wrapper>
	);
};


const mapDispatchToProps = {};
const mapStateToProps = (state: iRootState) => ({
	browser: state.browser,
});
export default connect(mapStateToProps, mapDispatchToProps)(ConnectionDrawer);

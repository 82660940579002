import * as React from 'react';
import { connect } from 'react-redux';
import { sumBy } from 'lodash';
import styled from 'styled-components';
import { Card, List, Progress } from 'antd';
import { CardProps } from 'antd/lib/card';

import { State as iRootState } from '../../types';
import { iState } from '@/state/models/stats/connections';
import { load } from '@/state/reducers/stats/connections';

const Wrapper = styled(Card)`
	height: 100%;
	.ant-card-body{
		h4 {
			margin: 0;
		}
	}
`;

interface Props extends CardProps {
	stats: iState;
	load: typeof load;
}

const StatsConnections: React.FunctionComponent<Props> = (props: Props) => {
	const { stats, load, ...rest } = props;

	React.useEffect(() => {
		const rc = props.stats;
		if (!rc.data.loading && !rc.data.loaded) load();
	}, []);

	const statsDataFormatted = () => {
		const sumBlocked = sumBy(stats.data.results, 'block_count');
		if (!stats.data.results.length) return [];

		const res = [];
		for (let i = 0; i < stats.data.results.length; i++) {
			const s = stats.data.results[i];
			res.push({
				title     : s.connection.alias,
				count     : s.block_count,
				color     : '#333',
				percentage: parseInt((s.block_count / (sumBlocked / 100)).toFixed(0)),
			});
		}
		return res;
	};

	return (
		<Wrapper {...rest} loading={stats.data.loading}>
			<List
				size="small"
				loading={stats.data.loading}
				split={false}
				bordered={false}
				dataSource={statsDataFormatted()}
				renderItem={(item: any) => (
					<List.Item>
						<List.Item.Meta
							title={<div style={{
								textTransform: 'uppercase',
								fontSize     : '.8rem',
							}}>{item.title} <b style={{
								float   : 'right',
								fontSize: '1.2rem',
							}}>
								{Number(item.count).toLocaleString()}
							</b></div>}
							description={<Progress
								showInfo={false}
								percent={item.percentage}
								strokeColor={item.color}
								size="small"/>}
						/>
					</List.Item>
				)}
			/>
		</Wrapper>
	);
};


const mapDispatchToProps = { load };
const mapStateToProps = (state: iRootState) => ({
	stats: state.stats_connections,
});
export default connect(mapStateToProps, mapDispatchToProps)(StatsConnections);

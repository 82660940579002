import * as React from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { Col, Row, Card } from 'antd';
import { IBrowser } from 'redux-responsive';

import { State as iRootState } from '@/types';
import { iState } from '@/state/models/device';
import { State as iStateConnections } from '@/state/models/connection';
import { load } from '@/state/reducers/devices';
import Layout from '@/components/layout';
import SEO from '@/components/seo';
import { knowledgebaseArticles } from '@/routes';
import DevicesList from '@/components/devices/list';
import DevicesDrawer from '@/components/devices/drawer';
import DevicesPagination from '@/components/devices/pagination';
import { t } from '@/utils';
import Devices from '@/components/public/static/devices.svg';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	devices: iState;
	browser: IBrowser;
	connections: iStateConnections;

	load: typeof load;
}

const DevicesPage: React.FunctionComponent<Props> = (props: Props) => {
	React.useEffect(() => {
		if (!props.connections.data.results.length) {
			navigate('/app/connections');
		} else {
			props.load();
		}
	}, []);

	const renderHasNoDevices = () => {
		return <>
			<Row gutter={0} style={{ textAlign: 'center' }}>
				<Col span={24}>
					<div style={{
						height    : 500,
						display   : 'block',
						width     : '100%',
						background: `transparent url(${Devices}) no-repeat center center`,
					}}
					>&nbsp;</div>
					<h2 style={{
						margin    : '40px auto 0 auto',
						fontWeight: 800,
						fontSize  : '3rem',
						display   : 'block',
					}}>
						{t('devices.title_none')}
					</h2>
				</Col>

				<Col span={24}>
					<p style={{
						display: 'block',
						margin : props.browser.lessThan.medium ? '0 10px' : '0 160px',
					}}>{t('devices.info')}</p>
				</Col>
			</Row>
		</>;
	};

	const isLoading = props.devices.data.loading && !props.devices.data.loaded;
	const hasDevices = props.devices.data.results.length > 0;

	return (
		<Layout titleProps={{
			title: 'Network devices', subTitle: <div>
				Reported devices from your network&nbsp;
				<a href={knowledgebaseArticles.devicesHowTo} target={'__blank'}>More</a>
			</div>,
		}}>
			<SEO title="Devices"/>

			{isLoading && (
				<Row gutter={[16, 16]}>
					<Col lg={8} md={12} xs={24}>
						<Card loading={true}/>
					</Col>
					<Col lg={8} md={12} xs={24} title={'ads'}>
						<Card loading={true}/>
					</Col>
					<Col lg={8} md={12} xs={24}>
						<Card loading={true}/>
					</Col>
				</Row>
			)}

			{hasDevices && (
				<div style={{ minHeight: 800, position: 'relative', paddingBottom: 80 }}>
					<DevicesList/>
					<DevicesDrawer/>

					<div style={{ textAlign: 'right', position: 'absolute', bottom: 20, right: 20 }}>
						<DevicesPagination/>
					</div>
				</div>
			)}

			{!hasDevices && !isLoading && renderHasNoDevices()}
		</Layout>
	);
};

const mapDispatchToProps = { load };
const mapStateToProps = (state: iRootState) => ({
	browser    : state.browser,
	devices    : state.devices,
	connections: state.connections,
});
export default connect(mapStateToProps, mapDispatchToProps)(DevicesPage);

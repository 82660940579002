import * as React from 'react';
import QRCOde from 'qrcode.react';

import styled from 'styled-components';

const Wrapper = styled.div`
	
`;

interface Props {
	cId: number;
	cIdentifier: string;
	token: string;
}

interface State {

}

export default class WireguardQr extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	get genQrString() {
		const { token, cId, cIdentifier } = this.props;
		const data = {
			token      : token,
			cId        : cId,
			cIdentifier: cIdentifier,
		};

		return JSON.stringify(data);
	}

	render() {
		const { token, cId, cIdentifier, ...rest } = this.props;
		return <Wrapper {...rest}>
			<QRCOde
				value={this.genQrString}
				size={240} renderAs={'svg'}
				includeMargin={true}
				bgColor={'#FFFFFF'}
			/>
		</Wrapper>;
	}
}

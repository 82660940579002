import * as React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm, Table, PageHeader } from 'antd';
import { TableProps } from 'antd/lib/table';

import PaginatedFilteredSortedTable, {
	Props as PFSProps,
	State as PFSState,
} from '../paginated-filtered-sorted-table';

import { State as RootState } from '../../types';
import RuleType from '../rule-type';
import { remove, load, paginate } from '../../state/reducers/rules';
import {
	iState as iRulesState,
	iModel as iRulesModel,
	iData as iDataRules,
	resultsPerPage,
} from '../../state/models/rules';
import { iState as iRuleCategoriesState } from '../../state/models/rule_categories';
import { iState as iSelectedRuleState } from '../../state/models/rule';
import { select as selectRule } from '../../state/reducers/rule';
import RuleForm from './rule_form';
import RulesForm from './form';
import { navigate } from 'gatsby';
import { State as iStateConnections } from '../../state/models/connection';

const RulesCard = styled(Card)`
	.ant-card-body {
		padding: 1px 0 0 0;
		margin-bottom: -2px;
	}
	
	tr {
		td {
			&:first-child {
				padding-left:20px!important;
			}
			&:last-child {
				padding-right:20px!important;
			}
		}
	}
	
	.ant-empty {
		min-height: 140px;
		padding-top: 40px;
	}
`;

interface Props {
	data: iDataRules;
	connections: iStateConnections;
	selectedRule: iSelectedRuleState;
	rules: iRulesState;
	rule_categories: iRuleCategoriesState;
	tableProps?: TableProps<any>;
	style?: any;
	remove: (id: number) => void;
	selectRule: (rule: iRulesModel | null) => void;
}

interface State {
	showform?: boolean;
	expandedRowKeys?: string[];
}

export class UserRules extends PaginatedFilteredSortedTable<PFSProps & Props, State & PFSState> {
	searchInput: any;

	state = {
		showform       : false,
		expandedRowKeys: [],
	};

	showForm = () => {
		this.setState({
			showform: !this.state.showform,
		});
	};

	componentDidMount(): void {
		super.componentDidMount();
		if (!this.props.connections.data.results.length) navigate('/app/connections');

	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		const { selectedRule } = this.props;

		if (selectedRule.rule == null && prevProps.selectedRule.rule != null) {
			this.setState({ expandedRowKeys: [] });
		}
	}

	onRowExpand = (expanded: boolean, record: iRulesModel) => {
		const { selectRule } = this.props;
		const keys = [];
		if (expanded) {
			keys.push(record.id.toString());
		}

		selectRule(expanded ? record : null);
		this.setState({ expandedRowKeys: keys });
	}

	render() {
		const { tableProps, selectedRule, rules, style, remove, selectRule } = this.props;

		const columns = [{
			title    : 'Hostname',
			dataIndex: 'rule',
			key      : 'rule',
			render   : (val: any, record: iRulesModel) => {
				return val;
			},
		}, {
			title    : 'Connections',
			dataIndex: 'connections',
			key      : 'connections',
			width    : 140,
			render   : (val: number[], record: iRulesModel) => {
				return val.length ? <span>{val.length} selected</span> : <i>all</i>;
			},
		}, {
			title    : 'Category',
			dataIndex: 'category',
			key      : 'category',
			width    : 100,
			render   : (val: number, record: iRulesModel) => {
				const { rule_categories } = this.props;
				return <RuleType category={rule_categories.data.results.filter(
					(rg) => rg.id == val,
				)[0]}/>;
			},
		}, {
			title    : '',
			dataIndex: 'options',
			key      : 'options',
			width    : 50,
			render   : (val: string, record: iRulesModel) => {
				return (
					<Popconfirm
						title="Delete record?"
						placement={'left'}
						onConfirm={() => remove(record.id)}
					>
						<Button type="danger" icon={<DeleteOutlined/>} size={'small'} ghost={true}/>
					</Popconfirm>
				);
			},
		}];


		const expandedRowRender = (record: iRulesModel) => {
			return selectedRule ? <div style={{ margin: '30px 60px 30px 0' }}>
				<RuleForm/>
			</div> : null;
		};

		return <RulesCard bordered style={style}>
			<PageHeader
				ghost={false}
				title="Filters"
				subTitle="Your own block/allow filters"
				extra={[
					<Button
						key={1}
						onClick={this.showForm}
						type={this.state.showform ? 'default' : 'primary'}
					>
						Create filters
					</Button>,
				]}
				style={{
					borderBottom: '1px solid #e8e8e8',
				}}
			>
				<div style={{
					display: this.state.showform ? 'block' : 'none',
				}}>
					<RulesForm/>
				</div>
			</PageHeader>

			<Table
				loading={rules.data.loading}
				pagination={{
					hideOnSinglePage: false,
					total           : rules.data.count,
					showTotal       : (total: number, range: number[]) => {
						return <div>
							<b>{range[0]}-{range[1]}</b> of <b>{Number(rules.data.count).toLocaleString()}</b> items
						</div>;
					},
					current         : rules.data.pageNumber,
					pageSize        : resultsPerPage,
					defaultPageSize : resultsPerPage,
					style           : {
						marginRight: 12,
					},
				}}
				rowKey={(record: iRulesModel) => record.id.toString()}
				expandedRowRender={expandedRowRender}
				expandedRowKeys={this.state.expandedRowKeys}
				expandRowByClick={true}
				onExpand={this.onRowExpand}
				columns={columns}
				showHeader={true}
				dataSource={rules.data.results}
				bordered={false}
				style={{ border: '0px' }}
				onChange={this.handleTableChange.bind(this)}
				{...tableProps}
			/>
		</RulesCard>;
	}
}

const mapStateToProps = (state: RootState) => {
	return {
		selectedRule   : state.rule,
		rules          : state.rules,
		connections    : state.connections,
		data           : state.rules.data,
		rule_categories: state.rule_categories,
	};
};

const mapDispatchToProps = {
	remove, load, paginate, selectRule,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(UserRules);

import * as React from 'react';
import Link from 'gatsby-link';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Form } from 'antd';

import { Input, Button, Row, Col, message } from 'antd';
import { State as RootState } from '../../types';
import { formFieldError, t } from '../../utils';
import { register as registerAction } from '../../state/reducers/register';
import { iState as iStateRegister } from '../../state/models/register';
import { iModel as iPlanModel, iState as iPlansState } from '../../state/models/plans';

const Wrapper = styled.div``;

const ForgotPassword = styled(Link)`
	text-align: right;
	
	&:hover {
		text-decoration: none;
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	plans: iPlansState;
	register: iStateRegister;
	registerAction: typeof registerAction;
	selectedPlan: iPlanModel | null;
	selectedPlanCycle: 'monthly' | 'bi-annually' | 'annually' | null;
}

const RegisterForm: React.FunctionComponent<Props> = (props: Props) => {
	const [form] = Form.useForm();
	const { register, registerAction } = props;

	const handleSumbit = (e: any) => {
		e.preventDefault();

		const email = form.getFieldValue('email');
		const username = form.getFieldValue('username');
		const password = form.getFieldValue('password');
		const passwordRe = form.getFieldValue('password_re');

		if (props.selectedPlan && props.selectedPlanCycle) {
			registerAction(email, username, password, passwordRe);
		}
	};

	React.useEffect(() => {
		if ((!props.selectedPlan || !props.selectedPlanCycle) && props.plans.data.results.length > 0) {
			message.error('Invalid plan');
		}
	}, [props.plans.data.results]);

	const reqR = () => Object.assign({}, { required: true, message: t('misc.form.validation_required') });

	return <Wrapper {...props}>
		<Form form={form} layout="vertical" style={{ width: '100%' }}>
			<Form.Item {...formFieldError('email', register.validationErrors)}>
				<Form.Item name="email" noStyle rules={[reqR()]}>
					<Input type={'email'} placeholder={'Email'} size={'large'}/>
				</Form.Item>
			</Form.Item>

			<Form.Item {...formFieldError('username', register.validationErrors)}>
				<Form.Item name="username" noStyle rules={[reqR()]}>
					<Input placeholder={'Username'} size={'large'}/>
				</Form.Item>
			</Form.Item>

			<Form.Item {...formFieldError('password', register.validationErrors)}>
				<Form.Item name="password" noStyle rules={[reqR()]}>
					<Input placeholder={'Password'} type={'password'} size={'large'}/>
				</Form.Item>
			</Form.Item>

			<Form.Item {...formFieldError('password_re', register.validationErrors)}>
				<Form.Item name="password_re" noStyle rules={[reqR()]}>
					<Input placeholder={'Password'} type={'password'} size={'large'}/>
				</Form.Item>
			</Form.Item>

			<Form.Item style={{ marginTop: 10 }}>
				<Button type={'primary'} block={true} size={'large'} onClick={handleSumbit}>
					Register
				</Button>
			</Form.Item>

			<Form.Item>
				<Row gutter={8}>
					<Col span={12}>
						Already a member?
					</Col>
					<Col span={12} style={{ textAlign: 'right' }}>
						<ForgotPassword to={'/app/login'}>Login</ForgotPassword>
					</Col>
				</Row>
			</Form.Item>
		</Form>
	</Wrapper>;
};


const mapStateToProps = (state: RootState) => {
	return {
		register: state.register,
		plans   : state.plans,
	};
};

const mapDispatchToProps = { registerAction };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RegisterForm);

import * as React from 'react';

import { Box, Flex } from '@rebass/grid';

interface Props {
	children: React.ReactNode;
	className?: string;
}

interface State {

}

export default class StandaloneFormPage extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return <Flex
			alignItems={'center'}
			justifyContent={'center'}
			className={this.props.className}
		>
			<Box alignSelf={'center'} className="container">
				{this.props.children}
			</Box>
		</Flex>;
	}
}

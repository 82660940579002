import * as React from 'react';
import { connect } from 'react-redux';

import { Row, Col } from 'antd';

import { State as iRootState } from '@/types';
import ProfileCard from '@/components/profile/card';
import StatsProtocols from '@/components/stats/protocols';
import StatsCurrentUsage from '@/components/stats/current_usage';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {

}

const LimitsStats: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Row gutter={[16, 20]}>
			<Col lg={8} xs={24}>
				<ProfileCard/>
			</Col>
			<Col lg={8} xs={24}>
				<StatsProtocols/>
			</Col>
			<Col lg={8} xs={24}>
				<StatsCurrentUsage/>
			</Col>
		</Row>
	);
};


const mapDispatchToProps = {};
const mapStateToProps = (state: iRootState) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(LimitsStats);

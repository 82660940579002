import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Card, Tag } from 'antd';

import styled from 'styled-components';
import { State as RootState } from '../../types';
import { iState as StateBilling, iModel } from '../../state/models/billing';
import { load } from '../../state/reducers/billing';
import { unixToDate } from '../../utils';

const Wrapper = styled(Card)`
	.ant-card-body {
		padding: 1px 0 0 0;
		margin-bottom: -2px;
	}
	
	.ant-empty {
		min-height: 140px;
		padding-top: 40px;
	}
`;

interface Props {
	billing: StateBilling;
	load: () => void;
}

interface State {

}

export class BillingList extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	componentDidMount(): void {
		this.props.load();
	}

	statusStr(status: number): React.ReactNode {
		const map: { [key: number]: React.ReactNode } = {
			0: <Tag color={'gray'}>Pending</Tag>,
			1: <Tag color={'green'}>Completed</Tag>,
			2: <Tag color={'orange'}>Completed</Tag>,
			3: <Tag color={'red'}>Completed</Tag>,
		};

		return map[status];
	}

	get columns() {
		return [{
			title    : <div style={{
				paddingLeft: 15,
			}}>Transaction Id</div>,
			dataIndex: 'transaction_id',
			key      : 'transaction_id',
			render   : (val: any, record: iModel) => {
				return <div style={{
					paddingLeft: 15,
				}}>
					<b>{val}</b>
				</div>;
			},
		}, {
			title    : 'Plan',
			dataIndex: 'plan.name',
			key      : 'plan.name',
			render   : (val: number, record: iModel) => {
				return val;
			},
			width    : 120,
			align    : 'left' as 'left',
		}, {
			title    : 'Created',
			dataIndex: 'created',
			key      : 'created',
			render   : (val: number, record: iModel) => {
				return unixToDate(val);
			},
			width    : 120,
			align    : 'center' as 'center',
		}, {
			title    : 'Amount',
			dataIndex: 'amount',
			key      : 'amount',
			align    : 'left' as 'left',
			render   : (val: number, record: iModel) => {
				return Number(val).toLocaleString('en-US', {
					currency             : 'USD',
					style                : 'currency',
					currencyDisplay      : 'symbol',
					minimumFractionDigits: 2,
				});
			},
			width    : 120,
		}, {
			title    : 'Status',
			dataIndex: 'status',
			key      : 'status',
			render   : (val: number, record: iModel) => {
				return this.statusStr(val);
			},
			width    : 120,
		}];
	}

	render() {
		const { billing, ...rest } = this.props;
		return <Table
			loading={billing.data.loading}
			rowKey={(record) => record.id.toString()}
			columns={this.columns}
			showHeader={true}
			dataSource={billing.data.results}
			bordered={false}
			style={{ border: '0px' }}
			onChange={console.log}
			pagination={false}
		/>;
	}
}

export class BillingCard extends React.Component<Props, State> {
	render(): React.ReactNode {
		const { billing, load, ...rest } = this.props;

		return <Wrapper {...rest} title={<div>Your latest <b>payments</b></div>}>
			<BillingList billing={billing} load={load}/>
		</Wrapper>;
	}
}

const mapStateToProps = (state: RootState) => {
	return {
		billing: state.billing,
	};
};

const mapDispatchToProps = { load };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BillingCard);

import * as React from 'react';
import { connect } from 'react-redux';
import { IBrowser } from 'redux-responsive';

import Layout from '../layout';
import SEO from '../seo';
import Connections from '../connections';
import { Alert, Button, Layout as ALayout, Row } from 'antd';
import Unblock from '../public/static/unblock.svg';
import { State as RootState } from '../../types';
import { formModalShow } from '@/state/reducers/connections';
import { Model as iModelConnection, State as iStateConnections } from '@/state/models/connection';
import FormModal from '../connections/form/modal';
import { t } from '@/utils';


interface Props {
	browser: IBrowser;
	connections: iStateConnections;
	formModalShow: (record?: iModelConnection) => void;
}

export class ConnectionsPage extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>> {
	render() {
		const { connections, formModalShow, browser } = this.props;
		const hasConnections = connections.data.results.length > 0;

		const renderHasNoConnections = () => {
			return <>
				<ALayout>
					<Row gutter={0} style={{ textAlign: 'center' }}>
						<div style={{
							height    : 500,
							display   : 'block',
							width     : '100%',
							background: `transparent url(${Unblock}) no-repeat center center`,
						}}
						>&nbsp;</div>
						<h2 style={{ margin: '40px auto 0 auto', fontWeight: 800, fontSize: '3rem' }}>
							{t('connections.title_none')}
						</h2>
						<p style={{
							margin: browser.lessThan.medium ? '0 10px' : '0 160px',
						}}>{t('connections.info')}</p>

						<Button block size={'large'}
							  type="primary"
							  style={{
								  maxWidth  : 500,
								  margin    : '40px auto 0 auto',
								  height    : 80,
								  fontWeight: 800,
								  fontSize  : '2rem',
							  }}
							  onClick={() => formModalShow()}
						>
							<b>{t('connections.create')}</b>
						</Button>
					</Row>
				</ALayout>
			</>;
		};

		const renderHasConnections = () => {
			return <>
				<ALayout>
					<Alert
						message={<b>{t('connections.title_info')}</b>}
						description={<div>
							{t('connections.info')}
						</div>} type="info"/>
				</ALayout>

				<div style={{ marginTop: 20 }}>
					<Connections/>
				</div>
			</>;
		};

		return <Layout titleProps={{ title: t('connections.title') }}>
			<SEO title={t('connections.title')}/>
			{hasConnections && renderHasConnections()}
			{!hasConnections && renderHasNoConnections()}

			<FormModal visible={connections.formModalShow || false}/>
		</Layout>;
	}
}

const mapStateToProps = (state: RootState) => {
	return {
		connections: state.connections,
		browser    : state.browser,
	};
};

const mapDispatchToProps = { formModalShow };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectionsPage);

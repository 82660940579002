import * as React from 'react';
import { connect } from 'react-redux';
import { Link, navigate } from 'gatsby';

import styled from 'styled-components';

import {
	BarsOutlined,
	DesktopOutlined,
	ForkOutlined,
	MenuUnfoldOutlined,
	AreaChartOutlined,
	SafetyOutlined,
} from '@ant-design/icons';

import { Badge, Menu, Avatar, Layout, Drawer } from 'antd';
import gravatarUrl from 'gravatar-url';

import Logo from '../logo';
import { State as RootState } from '@/types';
import { iState as AuthState } from '@/state/models/auth';
import { logout } from '@/state/reducers/auth';
import { iState as ProfileState } from '@/state/models/profile';
import { openInNewTab, showPhoneTablets, hidePhoneTablets } from '@/utils';
import { IBrowser } from 'redux-responsive';

const Wrapper = styled.div`
	.app-nav {
		padding-left: 20px;
		display: flex;

		.app-nav-logo {
			display: flex;
			min-width: 170px;
		}

		.app-nav-pages {
			flex: 1 1 0%;
			min-width: 0;
		}

		.app-nav-side {
			height: 100%; // without this we get a bigger height on our element
			display: flex;
			float: right;
			min-width: 250px;
			padding-right: 10px;

			.ant-menu {
				width: 100%;
				justify-content: flex-end;
			}
		}
	}

	// 990 breakpoint of title for the logo component
	@media only screen and (max-width: 990px) {
		.app-nav {
			.app-nav-logo {
				min-width: 70px;
			}
		}
	}
`;
const DrawerWrapper = styled(Drawer)`
	.ant-drawer-body {
		padding: 0 !important;
	}

	.sider {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		max-width: 100% !important;

		.wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			min-height: 100%;
			display: flex;
			flex-direction: column;
			align-items: stretch;
		}

		header {
			li:first-child {
				background: var(--bg-darker);
				border-bottom: 1px solid #000;
			}
		}

		main {
			flex-grow: 1;
			border-bottom: 1px solid #000;
		}

		header, main, footer {
			flex-shrink: 0;
		}

		.bottom-menu {
			display: table;
			width: 100%;
			background: var(--bg-darker);

			li {
				display: table-cell;
				text-align: center;
			}
		}
	}
`;

const Avt = styled(Avatar)`
	i.anticon.anticon-user {
		margin: 0;
	}
`;

interface Props {
	auth: AuthState;
	browser: IBrowser;
	profile: ProfileState;
	logout: () => void;
}

interface State {
	sidebarOpen: boolean;
}

export class AppNav extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {

	state = {
		sidebarOpen: false,
	};

	renderSidebarMenu() {
		const { profile, logout } = this.props;

		if (profile.data.loading || !profile.data.email) {
			return <React.Fragment/>;
		}

		return (
			<section className={'wrapper'}>
				<header>
					<Menu
						theme="dark"
						mode={'vertical'}
						defaultSelectedKeys={AppNav.selectedMenuItem}
					>
						<Menu.Item
							className={'avatar-item'} key={10} style={{ height: 'auto' }}
							onClick={() => navigate('/app/profile')}
						>
							<div style={{ lineHeight: '59px', verticalAlign: 'middle' }}>
								<Avt
									shape="square" src={gravatarUrl(profile.data.email, {
									size: 50,
								})}/>&nbsp;&nbsp;&nbsp;{profile.data.username}
							</div>
						</Menu.Item>

						<Menu.Item key={11} onClick={() => navigate('/app/dashboard')}>
							<AreaChartOutlined/> <b>Dashboard</b>
						</Menu.Item>
						<Menu.Item key={13} onClick={() => navigate('/app/connections')}>
							<SafetyOutlined/> <b>Configurations</b>
						</Menu.Item>
						<Menu.Item key={12} onClick={() => navigate('/app/filters')}>
							<ForkOutlined/> <b>Filters</b>
						</Menu.Item>
						<Menu.Item key={15} onClick={() => navigate('/app/devices')}>
							<DesktopOutlined/> <b>Devices</b>
						</Menu.Item>
						<Menu.Item key={14} onClick={() => navigate('/app/logs')}>
							<BarsOutlined/> <b>Logs</b>
						</Menu.Item>
					</Menu>
				</header>

				<main/>

				<footer>
					<Menu
						theme="dark"
						mode={'horizontal'}
						defaultSelectedKeys={AppNav.selectedMenuItem}
						className={'bottom-menu'}
					>
						<Menu.Item key={5} onClick={() => navigate('/app/profile')}>
							Profile
						</Menu.Item>
						<Menu.Item key={6} onClick={() => navigate('/app/billing')}>
							Billing
						</Menu.Item>
						<Menu.Item onClick={() => {
							logout();
							// @TODO: navigate inside the saga not here!
							navigate('/app/login');
						}}>
							Logout
						</Menu.Item>
					</Menu>
				</footer>
			</section>
		);
	}

	toggleSidebar() {
		this.setState({ sidebarOpen: true });
	}

	static get selectedMenuItem() {
		if (typeof window !== 'undefined') {
			switch (window.location.pathname) {
				case '/app/filters':
					return ['3', '12'];
				case '/app/connections':
					return ['13', '2'];
				case '/app/profile':
					return ['5'];
				case '/app/billing':
					return ['6'];
				case '/app/devices':
					return ['15', `151`];
				case '/app/logs':
					return ['7', '14'];
				case '/app/download':
					return ['10'];
				case '/':
					return ['00000'];
				default:
					return ['1', '11'];
			}
		}
	}

	renderMenu(mode: 'horizontal' | 'vertical') {
		const { profile, logout } = this.props;

		if (mode == 'vertical') return this.renderSidebarMenu();

		return (
			<div className={'app-nav'}>
				<div className="app-nav-logo">
					<Link to={'/'}>
						<Logo width={30} style={{
							lineHeight   : '64px',
							verticalAlign: 'middle',
						}} imgStyles={{
							verticalAlign: 'middle',
						}} title={'DnsAdBlock'}/>
					</Link>
				</div>

				<div className={'app-nav-pages'}>
					<Menu
						theme="dark"
						mode={mode}
						defaultSelectedKeys={AppNav.selectedMenuItem}
						style={hidePhoneTablets(this.props.browser)}
					>
						<Menu.Item
							key={1} onClick={() => navigate('/app/dashboard')}
						>
							<AreaChartOutlined/> <b>Dashboard</b>
						</Menu.Item>
						<Menu.Item
							key={2}
							onClick={() => navigate('/app/connections')}
						>
							<SafetyOutlined/> <b>Configurations</b>
						</Menu.Item>
						<Menu.Item
							key={3}
							onClick={() => navigate('/app/filters')}
						>
							<ForkOutlined/> <b>Filters</b>
						</Menu.Item>
						<Menu.Item
							key={151}
							onClick={() => navigate('/app/devices')}
						>
							<DesktopOutlined/> <b>Devices</b>
						</Menu.Item>
						<Menu.Item
							key={7}
							onClick={() => navigate('/app/logs')}
						>
							<BarsOutlined/> <b>Logs</b>
						</Menu.Item>


					</Menu>
				</div>

				<div className="app-nav-side">
					<Menu
						theme="dark"
						mode={mode}
						defaultSelectedKeys={AppNav.selectedMenuItem}
						style={hidePhoneTablets(this.props.browser)}
					>
						<Menu.Item
							key={10}
							onClick={() => navigate('/app/download')}
							className={'mobile-hide tablet-hide'}>
							Download
						</Menu.Item>

						<Menu.SubMenu
							className={'mobile-hide tablet-hide'}
							title={<Badge count={0}>
								{<Avt shape="square"
									src={gravatarUrl(profile.data.email || 'test@test.com', {
										size   : 50,
										default: 'https://gravatar.com/avatar/00000000000000000000000000000000',
									})}/>}
							</Badge>}
							key={'user.avatar'}
						>
							<Menu.Item
								key={5}
								onClick={() => navigate('/app/profile')}>Profile</Menu.Item>
							<Menu.Item
								key={6}
								onClick={() => navigate('/app/billing')}>Billing</Menu.Item>
							<Menu.Item key={8} onClick={() => navigate('/support')}>Support</Menu.Item>
							<Menu.Item
								key={9}
								onClick={() => openInNewTab('https://knowledgebase.dnsadblock.com/')}>
								Knowledgebase
							</Menu.Item>
							<Menu.Item onClick={() => {
								logout();
								navigate('/app/login');
							}}>Logout</Menu.Item>
						</Menu.SubMenu>
					</Menu>

					<Menu
						theme="dark"
						mode={mode}
						defaultSelectedKeys={AppNav.selectedMenuItem}
						style={showPhoneTablets(this.props.browser)}
						className={'cacat'}
					>
						<Menu.Item
							key={999}
							onClick={(param: any) => this.toggleSidebar()}
							className={'mobile-show tablet-show'}
						>
							<MenuUnfoldOutlined
								style={{
									fontSize     : '1.2rem',
									color        : 'white',
									verticalAlign: 'middle',
								}}/>
						</Menu.Item>
					</Menu>
				</div>
			</div>
		);
	}

	render() {
		const { Sider } = Layout;

		return <Wrapper>
			{this.renderMenu('horizontal')}

			<Layout hasSider={true}>
				<DrawerWrapper
					placement="right"
					closable={false}
					visible={this.state.sidebarOpen}
					width={'90%'}
					className={'sidebar'}
					onClose={() => this.setState({ sidebarOpen: false })}
				>
					<Sider className={'sider'} theme={'dark'}>
						{this.renderMenu('vertical')}
					</Sider>
				</DrawerWrapper>
			</Layout>
		</Wrapper>;
	}
}

const mapStateToProps = (state: RootState) => {
	return {
		browser: state.browser,
		auth   : state.auth,
		profile: state.profile,
	};
};

const mapDispatchToProps = { logout };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AppNav);

import * as React from 'react';
import { connect } from 'react-redux';
import { Pagination } from 'antd';
import { IBrowser } from 'redux-responsive';

import { State as iRootState } from '../../types';
import { iState as iStateLogs } from '../../state/models/logs';
import { paginate } from '../../state/reducers/logs';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	logs: iStateLogs;
	browser: IBrowser;
	paginate: typeof paginate;
}

const LogsPagination: React.FunctionComponent<Props> = (props: Props) => {
	const onShowSizeChange = (current: number, pageSize: number) => {
		props.paginate({
			pageNumber: 1,
			pageSize  : pageSize,
		});
	};

	const onChange = (page: number) => {
		props.paginate({
			pageNumber: page,
		});
	};

	return (
		<Pagination
			showSizeChanger={props.browser.greaterThan.small}
			onChange={onChange}
			onShowSizeChange={onShowSizeChange}
			defaultCurrent={1}
			pageSize={props.logs.data.pageSize}
			current={props.logs.data.pageNumber}
			total={props.logs.data.count}
			pageSizeOptions={['10', '20', '50', '100', '500']}
		/>
	);
};


const mapDispatchToProps = { paginate };
const mapStateToProps = (state: iRootState) => ({
	logs   : state.logs,
	browser: state.browser,
});
export default connect(mapStateToProps, mapDispatchToProps)(LogsPagination);

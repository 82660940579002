import * as React from 'react';
import { connect } from 'react-redux';

import { Input } from 'antd';
import styled from 'styled-components';

import { PaginationPayload, State as RootState } from '../../../types';
import { FILTER_COLUMN_QNAME, load, paginate } from '../../../state/reducers/logs';
import { whitelist } from '../../../state/reducers/rules';
import { iState } from '../../../state/models/logs';

const Wrapper = styled.div``;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	logs: iState;
	paginate: (params: PaginationPayload) => void;
}

const FilterLogsSearch: React.FunctionComponent<Props> = ({ logs, paginate, ...rest }) => {
	const [search, setSearch] = React.useState('');
	return (
		<Wrapper {...rest}>
			<Input.Search
				placeholder="Search"
				size={'large'}
				style={{ width: '100%' }}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				onSearch={(v: string) => {
					let existingFilters = logs.data.filters.filter(e => e.column != FILTER_COLUMN_QNAME);
					if (v) existingFilters.push({ column: FILTER_COLUMN_QNAME, query: v });
					paginate({ filters: existingFilters, pageNumber: 1 });
				}}
				enterButton
				allowClear
			/>
		</Wrapper>
	);
};

const mapStateToProps = (state: RootState) => {
	return {
		logs: state.logs,
	};
};

const mapDispatchToProps = { load, paginate, whitelist };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(FilterLogsSearch);

import * as React from 'react';
import { connect } from 'react-redux';
import { List, Skeleton, Switch } from 'antd';

import { State as iRootState } from '@/types';
import { Model as iModelConnection, State as iConnectionsState } from '@/state/models/connection';
import { update as updateConnection } from '@/state/reducers/connections';
import {
	iState as iRuleCategoriesState,
	iModel as iRuleCategoriesModel,
	TYPE_BLOCK,
} from '@/state/models/rule_categories';
import { getExpanded } from '@/state/selectors/connections';

interface group {
	key: string;
	title: string;
	info: any;
	description?: any;
	render: (val: string | number | boolean, record: iModelConnection) => any;
}

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	connection: iModelConnection;
	categories: iRuleCategoriesState;
	connections: iConnectionsState;
	updateConnection: typeof updateConnection;
}

const BlockGroups: React.FunctionComponent<Props> = (props: Props) => {
	const { categories } = props;
	const groups: group[] = [];
	const booleanRenderer = (field: string, val: string | number | boolean, record: iModelConnection): React.ReactNode => {
		return '';
	};

	const actionRenderer = (field: string, val: string | number | boolean, record: iModelConnection): React.ReactNode => {
		const identifier = field.split('block-')[1];
		const category = categories.data.results.filter((c) => c.identifier == identifier)[0];
		let record_categories: number[] = record.rules_categories;

		return <Switch
			checked={record_categories.indexOf(category.id) !== -1}
			onChange={(val: boolean) => {
				if (val) {
					//	we add it when val is true
					record_categories.push(category.id);
					record_categories = record_categories.filter(
						(value, index, self) => self.indexOf(value) === index,
					);
				} else {
					record_categories = record_categories.filter((value) => value !== category.id);
				}

				props.updateConnection(Object.assign({}, record, {
					['rules_categories']: record_categories,
				}));
			}}/>;
	};

	categories.data.results.forEach((rc: iRuleCategoriesModel) => {
		if (rc.type == TYPE_BLOCK) {
			groups.push({
				title : rc.name,
				key   : `block-${rc.identifier}`,
				info  : (record: iModelConnection) => '',
				render: (val, record) => {
					return booleanRenderer(rc.identifier, val, record);
				},
			});
		}
	});

	return (
		<List
			size={'small'}
			loading={props.connections.data.loading}
			itemLayout="horizontal"
			bordered={false}
			dataSource={[props.connection]}
			renderItem={(item: iModelConnection) => groups.map((prop: any, i: number) => {
				return <List.Item
					key={prop.key}
					actions={[actionRenderer(prop.key, item[prop.key], item)]}>
					<Skeleton title={false} loading={false} active>
						<List.Item.Meta
							avatar={prop.render(item[prop.key], item)}
							title={prop.title}
							description={prop.info(item)}
						/>
					</Skeleton>
				</List.Item>;
			})}
		/>
	);
};


const mapDispatchToProps = { updateConnection };
const mapStateToProps = (state: iRootState) => ({
	categories : state.rule_categories,
	connection : getExpanded(state),
	connections: state.connections,
});
export default connect(mapStateToProps, mapDispatchToProps)(BlockGroups);

import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CreditCardOutlined, DesktopOutlined, UserOutlined } from '@ant-design/icons';
import { Steps } from 'antd';

import SEO from '../seo';
import Logo from '../logo';
import { LogoWrapper, StandalonePage } from './login';
import RegisterForm from '../register/form';
import { State as RootState } from '@/types';
import { register as registerAction } from '@/state/reducers/register';
import { show as showPayment } from '@/state/reducers/payment';
import { iState as iProfileState } from '@/state/models/profile';
import { iModel as iPlanModel, iState as iPlansState } from '@/state/models/plans';
import { navigate } from 'gatsby';

const WSteps = styled.div`
	position: absolute;
	top: 40px;
	left: 40px;
	right: 40px;
	font-weight: 600;
	
	@media (max-width: 700px) {
		display: none;
	}
`;

const Head = styled.h1`
	text-align: center;
	margin-bottom: 30px;
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	path?: string;
	plans: iPlansState;
	profile: iProfileState;
	showPayment: typeof showPayment;
}

const RegisterPage: React.FunctionComponent<Props> = (props: Props) => {
	const { plans, profile, showPayment } = props;

	React.useEffect(() => {
		checkShowPayment();
	}, []);

	React.useEffect(() => {
		checkShowPayment();
	}, [profile.data.email]);

	const checkShowPayment = () => {
		if (selectedPlan() && selectedPlanCycle()) {
			if (selectedPlanIsFree() && profile.data.email) {
				navigate('/app/connections');
			} else {
				const p = selectedPlan();
				const c = selectedPlanCycle();
				if (p && c) showPayment(p, c);
			}
		}
	};

	const selectedPlanCycle = (): 'monthly' | 'bi-annually' | 'annually' | null => {
		const urlParams = new URLSearchParams(window.location.search);
		switch (urlParams.get('cycle')) {
			case 'monthly':
				return 'monthly';
			case 'bi-annually':
				return 'bi-annually';
			case 'annually':
				return 'annually';
		}
		return null;
	};

	const selectedPlan = (): iPlanModel | null => {

		const urlParams = new URLSearchParams(window.location.search);
		const plan = urlParams.get('plan');

		let selectedPlan = null;
		for (let i = 0; i < plans.data.results.length; i++) {
			const p = plans.data.results[i];
			if (p.id.toString() == plan) {
				selectedPlan = p;
			}
		}

		return selectedPlan;
	};

	const selectedPlanIsFree = () => {
		const selected = selectedPlan();
		if (!selected) return false;
		//	yes, yes *1 because our price may sit as a string
		return selected?.price_monthly * 1 === 0;
	};

	return (
		<StandalonePage>
			<SEO title="Register" keywords={[
				`dns`, `ad block`, `block ads`, `block porn`, `block tracking`,
				`block phishing`, `block fake news`,
			]}/>

			<LogoWrapper>
				<Logo width={90} style={{ verticalAlign: 'middle', marginLeft: 3, marginTop: 2 }}/>
			</LogoWrapper>

			{!profile.data.email && <Head>Create your account</Head>}
			{profile.data.email && <Head>One more...small detail</Head>}

			<WSteps>
				<Steps style={{ width: '100%' }}>
					<Steps.Step
						status={profile.data.email ? 'finish' : 'process'}
						title="Register"
						icon={<UserOutlined/>}/>
					{!selectedPlanIsFree() && (
						<Steps.Step
							status={profile.data.email ? 'process' : 'wait'}
							title="Payment"
							icon={<CreditCardOutlined/>}
						/>
					)}
					<Steps.Step status="wait" title="Dashboard" icon={<DesktopOutlined/>}/>
				</Steps>
			</WSteps>

			{!profile.data.email && (
				<RegisterForm
					className={'form'}
					selectedPlan={selectedPlan()}
					selectedPlanCycle={selectedPlanCycle()}
				/>
			)}
		</StandalonePage>
	);
};

const mapStateToProps = (state: RootState) => {
	return {
		plans  : state.plans,
		profile: state.profile,
	};
};

const mapDispatchToProps = { registerAction, showPayment };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RegisterPage);

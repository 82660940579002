import * as React from 'react';
import { connect } from 'react-redux';

import { State as iRootState } from '../../../types';

import LogsFilterCategory from './category';
import LogsFilterDevices from './device';
import LogsFilterConnection from './connection';
import LogsFilterType from './type';
import { iState as iModelState } from '../../../state/models/logs';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	logs: iModelState;
}

const LogsFilter: React.FunctionComponent<Props> = (props: Props) => {
	const hasBlockedFilter = props.logs.data.filters.filter(f => f.column == 'type' && f.query == '-1').length > 0;
	return (
		<>

			<LogsFilterType/>

			<div style={{ marginTop: 20, display: hasBlockedFilter ? 'inherit' : 'none' }}>
				<LogsFilterCategory/>
			</div>

			<div style={{ marginTop: 20 }}>
				<LogsFilterDevices/>
			</div>

			<div style={{ marginTop: 20 }}>
				<LogsFilterConnection/>
			</div>
		</>
	);
};


const mapDispatchToProps = {};
const mapStateToProps = (state: iRootState) => ({
	logs: state.logs,
});
export default connect(mapStateToProps, mapDispatchToProps)(LogsFilter);

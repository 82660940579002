import * as React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import debounce from 'lodash/debounce';

import { State as iRootState } from '@/types';
import { iState as iStateLogs } from '@/state/models/logs';
import { State as iStateConnections } from '@/state/models/connection';
import { filter, FILTER_COLUMN_CONNECTION } from '@/state/reducers/logs';
import { load, paginate } from '@/state/reducers/connections';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	logs: iStateLogs;
	connections: iStateConnections;

	load: typeof load;
	paginate: typeof paginate;
	filter: typeof filter;
}

const LogsFilterConnection: React.FunctionComponent<Props> = (props: Props) => {
	const { Option } = Select;
	const handleChange = (v: string) => {
		props.filter({ column: FILTER_COLUMN_CONNECTION, query: v });
	};

	const handleSearch = (v: string) => {
		props.paginate({
			filters   : v ? [{
				column: FILTER_COLUMN_CONNECTION,
				query : v,
			}] : [],
			pageNumber: 1,
		});
	};

	const handleFocus = () => {
		if (!props.connections.data.loaded && !props.connections.data.loading) props.load();
	};

	const currentValue = (): string | undefined => {
		const curr = props.logs.data.filters.filter(f => f.column == FILTER_COLUMN_CONNECTION);
		return curr.length ? curr[0].query : undefined;
	};

	return (
		<Select
			showSearch
			style={{ width: '100%' }}
			placeholder={'Connection'}
			size={'large'}
			value={currentValue()}
			showArrow={true}
			allowClear={true}
			filterOption={false}
			labelInValue={false}
			loading={props.connections.data.loading}
			onFocus={handleFocus}
			onSearch={debounce(handleSearch, 800)}
			onChange={handleChange}
			notFoundContent={null}
		>
			{props.connections.data.results.map((d) => (
				<Option key={d.id} value={d.id}>{d.alias}</Option>
			))}
		</Select>
	);
};

const mapDispatchToProps = { load, paginate, filter };
const mapStateToProps = (state: iRootState) => ({
	logs       : state.logs,
	connections: state.connections,
});
export default connect(mapStateToProps, mapDispatchToProps)(LogsFilterConnection);

import * as React from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import { Button, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import Layout from '../layout';
import SEO from '../seo';
import ConnectionStats from '../dashboard/charts/connections';
import { State as iRootState } from '@/types';
import { t } from '@/utils';
import { iState as iProfileState } from '@/state/models/profile';
import { State as StateConnections } from '@/state/models/connection';
import { formModalShow } from '@/state/reducers/connections';
import QuickStats from '../dashboard/quick-stats';
import LimitsStats from '../dashboard/limits';
import {
	iState as iStateConnectionStats,
	load as loadStats,
	loadForConnection as loadConnectionStats,
} from '@/state/reducers/connection_stats';
import { load as loadCurrentUsage } from '@/state/reducers/stats/current_usage';
import { load as loadProtocolsStats } from '@/state/reducers/stats/protocols';

interface Props {
	profile: iProfileState;
	connections: StateConnections;
	connection_stats: iStateConnectionStats;

	loadStats: typeof loadStats;
	loadCurrentUsage: typeof loadCurrentUsage;
	loadConnectionStats: typeof loadConnectionStats;
	loadProtocolsStats: typeof loadProtocolsStats;
	formModalShow: typeof formModalShow;
}

interface State {
	createConnectionWarningShown: boolean;
}

export class Dashboard extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	state = {
		createConnectionWarningShown: false,
	};

	componentDidMount(): void {
		if (!this.props.connections.data.results.length) navigate('/app/connections');
		this.props.loadStats();
		this.props.loadCurrentUsage();
		this.props.loadProtocolsStats();
	}

	statsRefresh = () => {
		const { connection_stats, loadStats, loadConnectionStats } = this.props;
		if (connection_stats.connectionId) {
			loadConnectionStats(connection_stats.connectionId);
		} else {
			loadStats();
		}
	};

	render() {
		return <Layout titleProps={{ title: 'At a glance' }}>
			<SEO title="Dashboard"/>

			<LimitsStats/>

			<div style={{ marginTop: 20 }}>
				<QuickStats/>
			</div>

			<div style={{ marginTop: 20, position: 'relative' }}>
				<ConnectionStats title={t('stats.timeline_title')}/>
				<div style={{
					position: 'absolute',
					bottom  : 20,
					right   : 20,
				}}>
					<Tooltip title="reload">
						<Button
							shape="circle"
							icon={<ReloadOutlined
								spin={this.props.connection_stats.loading}
							/>}
							onClick={this.statsRefresh}
						/>
					</Tooltip>
				</div>
			</div>
		</Layout>;
	}
}


const mapStateToProps = (state: iRootState) => {
	return {
		profile         : state.profile,
		connections     : state.connections,
		connection_stats: state.connection_stats,
	};
};

const mapDispatchToProps = { formModalShow, loadStats, loadConnectionStats, loadCurrentUsage, loadProtocolsStats };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Dashboard);


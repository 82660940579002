import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Statistic, Card, Progress, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons/lib';

import { State as iRootState } from '@/types';
import { iState } from '@/state/models/stats/current_usage';
import { t, percentValFromTotal } from '@/utils';

const Wrapper = styled(Card)`
	height: 100%;
	.legend {
		color: #666;
		b {
			color: #333;
		}
	}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	stats: iState;
}

const StatsCurrentUsage: React.FunctionComponent<Props> = (props: Props) => {
	const percent = percentValFromTotal(props.stats.data.total, props.stats.data.allowed);

	const progressColor = (): any => {
		if (percent > 90) return 'var(--color-accent-secondary)';
		return 'var(--color-primary)';
	};

	return (
		<Wrapper loading={props.stats.loading}>
			<Statistic
				title={<div>
					<Tooltip title={t('stats.monthly_usage_desc')} placement={'bottom'}>
						<InfoCircleFilled/>
					</Tooltip> {t('stats.monthly_usage').replace('%s',
					(new Date).toLocaleString('default', { month: 'long' }))}
				</div>}
				value={percent}
				precision={2}
				valueStyle={{ fontWeight: 800, fontSize: '3rem' }}
				suffix="%"
			/>
			<Progress
				percent={percent}
				showInfo={false}
				strokeColor={progressColor()}
			/>
			<span className={'legend'}>
				<b>{Number(props.stats.data.total).toLocaleString()}</b> of&nbsp;
				<b>{Number(props.stats.data.allowed).toLocaleString()}</b> allowed for this month
			</span>
		</Wrapper>
	);
};


const mapDispatchToProps = {};
const mapStateToProps = (state: iRootState) => ({
	stats: state.stats_current_usage,
});
export default connect(mapStateToProps, mapDispatchToProps)(StatsCurrentUsage);
